import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PlatformService } from './platform.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReadTextService {
  url;
  readingFinishedBearer = new Subject();
  audioDownloadedBearer = new Subject();
  audioDownloading = false;
  waitingAudio = [];
  audioLoading = false;
  allItemsToRead = [
    ['sentenceAudio', 'sentenceAudioDownloaded', 'sentence'],
    ['wordAudio', 'wordAudioDownloaded', 'correctAnswer'],
    ['polishAudio', 'polishAudioDownloaded', 'translation'],
    ['blankAudio', 'blankAudioDownloaded', 'blank'],
    ['blankAudioGB', 'blankAudioDownloadedGB', 'blank'],
    [
      'childrenSentenceAudio',
      'childrenSentenceAudioDownloaded',
      'childrenSentence',
    ],
    [
      'childrenSentenceAudioGB',
      'childrenSentenceAudioGBDownloaded',
      'childrenSentence',
    ],
    ['childrenBlankAudioGB', 'childrenBlankAudioGBDownloaded', 'childrenBlank'],
    ['childrenBlankAudio', 'childrenBlankAudioDownloaded', 'childrenBlank'],
    ['sentenceAudioGB', 'sentenceAudioGBDownloaded', 'sentence'],
    ['wordAudioGB', 'wordAudioGBDownloaded', 'correctAnswer'],
    ['termAudioUS', 'termAudioUSDownloaded', 'question'],
    ['definitionAudioUS', 'definitionAudioUSDownloaded', 'answer'],
  ];
  public readingPositive: boolean;
  private audio = new Audio();
  private xhr: XMLHttpRequest;
  private msg: any;
  private xhr2: XMLHttpRequest;
  private xhr3: XMLHttpRequest;
  private interval;
  readingFromBlob: boolean;
  readingFromMs: boolean;
  readingFromRepo: boolean;
  readingFromWindow: boolean;

  constructor(
    private http: HttpClient,
    private platform: PlatformService,
    private authService: AuthenticationService
  ) {
    this.url = platform.url;
    this.interval = setInterval(() => {
      if (!this.audioDownloading && this.waitingAudio.length > 0) {
        this.downloadSingleAudio(
          this.waitingAudio[0].url,
          this.waitingAudio[0].property,
          this.waitingAudio[0].itemId
        );
        this.waitingAudio.splice(0, 1);
      }
    }, 600);
  }

  stopReading() {
    this.readingFromBlob = false;
    this.readingFromMs = false;
    this.readingFromRepo = false;
    this.readingFromWindow = false;
    this.audio.pause();
    // if (this.xhr) {
    //   this.xhr.abort();
    // }
    // if (this.xhr2) {
    //   this.xhr2.abort();
    // }
    // if (this.xhr3) {
    //   this.xhr3.abort();
    // }
    this.readingPositive = false;
    this.audioLoading = false;
  }
  stopReadingMultiple() {
    this.stopReading();
    setTimeout(() => {
      this.stopReading();
      setTimeout(() => {
        this.stopReading();
        setTimeout(() => {
          this.stopReading();
          setTimeout(() => {
            this.stopReading();
            setTimeout(() => {
              this.stopReading();
              setTimeout(() => {
                this.stopReading();
                setTimeout(() => {
                  this.stopReading();
                }, 50);
              }, 50);
            }, 50);
          }, 50);
        }, 50);
      }, 50);
    }, 50);
  }
  readTextFromBlob(url, blob, fallbackText, id, speed?: number, prop?: string) {
    this.readingFromBlob = true;
    this.readingFromMs = false;
    this.readingFromRepo = false;
    this.readingFromWindow = false;

    if (!this.audioLoading && !this.readingPositive) {
      this.readingPositive = true;

      this.audioLoading = true;

      const audioIsBlob = blob instanceof Blob;
      const createBlob = new Promise((resolve, reject) => {
        try {
          const src = window.URL.createObjectURL(blob);
          if (src) {
            this.audio.src = src;
          }
          // this.readTextFromRepo(url, fallbackText, speed);

          resolve(null);
        } catch (err) {
          reject('file corrupted');
        }
      });
      createBlob
        .then((blob2) => {
          this.audio.playbackRate = speed ? speed : 1;

          this.audio.volume = 1;
          this.audio
            .play()
            .then(() => {
              console.warn('reading from blob');
            })
            .catch((err) => {
              this.readingPositive = true;
              this.audioLoading = false;
              this.stopReading();

              this.readTextFromRepo(url, fallbackText, id, speed, prop);
            });
        })
        .catch((err) => {
          this.stopReading();

          this.readTextFromRepo(url, fallbackText, id, speed, prop);
        });
      this.audio.addEventListener('ended', () => {
        if (this.readingFromBlob) {
          this.readingFinishedBearer.next({ readText: fallbackText, id });
          this.readingFromBlob = false;

          this.readingPositive = false;
        }
      });
    }
  }

  readTextFromRepo(url, fallbackText, id, speed?: number, prop?: string) {
    this.readingFromBlob = false;
    this.readingFromMs = false;
    this.readingFromRepo = true;
    this.readingFromWindow = false;
    fallbackText = fallbackText ? fallbackText.replace(/(_)\w+/g, 'blank') : '';
    this.audioLoading = true;

    console.error('reading from repo');

    this.xhr = new XMLHttpRequest();
    this.xhr.open('GET', this.url + '/api/read/getaudiodata?url=' + url);
    this.xhr.responseType = 'arraybuffer';

    this.xhr.onload = () => {
      const audiofileCreated = new Promise((resolve, reject) => {
        const blob = new Blob([this.xhr.response], {
          type: 'audio/wav',
        });
        if (blob.size > 1000) {
          resolve(blob);
        } else {
          reject('file in repo corrupted!');
        }
      });
      audiofileCreated
        .then((blob: any) => {
          this.audio.addEventListener('ended', () => {
            if (this.readingFromRepo) {
              this.readingFinishedBearer.next({ readText: fallbackText, id });
              this.readingFromRepo = false;
              this.readingPositive = false;
            }
          });
          this.audio.src = window.URL.createObjectURL(blob);
          this.audio.playbackRate = speed ? speed : 1;
          this.audio.volume = 1;

          this.audio.play().then(() => {
            this.readingPositive = true;
            this.audioLoading = false;
          });
        })
        .catch((err) => {
          this.readTextFromMs(fallbackText, 'force', null, 1, null, prop);
        });
    };
    this.xhr.send();
  }

  wait(ms) {
    return new Promise((_, reject) => {
      setTimeout(() => reject(new Error('timeout succeeded')), ms);
    });
  }

  getAudioData(url, property, itemId) {
    if (url && !this.audioDownloading && this.waitingAudio.length === 0) {
      this.downloadSingleAudio(url, property, itemId);
    } else {
      this.waitingAudio.push({ url, property, itemId });
    }
  }

  downloadSingleAudio(url, property, itemId) {
    {
      this.xhr3 = new XMLHttpRequest();
      this.xhr3.open('GET', this.url + '/api/read/getaudiodata?url=' + url);
      this.xhr3.responseType = 'arraybuffer';

      this.xhr3.onload = () => {
        const blob = new Blob([this.xhr3.response], { type: 'audio/wav' });
        this.audioDownloadedBearer.next({
          audioBlob: blob,
          property,
          itemId,
        });
        this.audioDownloading = false;
      };
      this.xhr3.send();
      this.audioDownloading = true;
    }
  }

  readTextFromMs(text, id, uk?, speed?, generateAudioData?, prop?, es?) {
    if (this.xhr) {

      this.xhr.abort();
    }
    if (this.xhr2) {
      this.xhr2.abort();

    }
    this.xhr = null;
    this.readingFromBlob = false;
    this.readingFromMs = true;
    this.readingFromRepo = false;
    this.readingFromWindow = false;
    console.log('reading from Ms');

    let url;
    if (es) {
      url =
        this.url +
        '/api/read/readfromazurespanish?textToRead=' +
        text +
        '&token=' +
        this.authService.getToken();
    } else {
      if (prop?.includes('translation')) {
        url =
          this.url +
          '/api/read/readfromazure?textToRead=' +
          text +
          '&token=' +
          this.authService.getToken();
      } else {
        if (!uk) {
          url =
            this.url +
            '/api/read/readfromazureenglish?textToRead=' +
            text +
            '&token=' +
            this.authService.getToken();
        }
        if (uk) {
          url =
            this.url +
            '/api/read/readfromazureenglishuk?textToRead=' +
            text +
            '&token=' +
            this.authService.getToken();
        }
      }
    }
    if (!generateAudioData) {
      this.xhr2 = new XMLHttpRequest();
      this.xhr2.open('GET', url);
      this.xhr2.responseType = 'arraybuffer';
      this.xhr2.timeout = 50000;
      this.xhr2.ontimeout = () => {
        // this.windowRead(text ? text : 'Error!', 'force', false);
        return;
      };
      this.xhr2.onerror = () => {
        // this.windowRead(text ? text : 'Error!', 'force', false);
        return;

        // throw new Error('Error while getting audio from MS!!');
      };
      this.xhr2.onload = () => {
        const audiofileCreated2 = new Promise((resolve, reject) => {
          const blob = new Blob([this.xhr2.response], {
            type: 'audio/wav',
          });
          if (blob.size > 1000) {
            resolve(blob);
          } else {
          }
        });
        const result = Promise.race([this.wait(2000), audiofileCreated2])
          .then((blob: any) => {
            this.audio.src = window.URL.createObjectURL(blob);
            this.audio.playbackRate = speed ? speed : 1;
            this.audio.volume = 1;

            this.audio.play().then(() => {
              this.audioLoading = false;

              this.readingPositive = true;
            });
          })
          .catch((err) => {
            // this.readTextFromMs(text ? text : 'error', id);
          });
        this.audio.addEventListener('ended', () => {
          if (this.readingFromMs) {
            this.readingFinishedBearer.next({ readText: text, id });
            this.readingFromMs = false;
            this.readingPositive = false;
          }
        });
      };
      this.xhr2.send();
    }
  }

  windowRead(initialItem, id, array, speed?, lang?) {
    console.log(
      '🚀 ~ file: read-text.service.ts:379 ~ ReadTextService ~ windowRead ~ initialItem:',
      initialItem
    );
    this.readingFromBlob = false;
    this.readingFromMs = false;
    this.readingFromRepo = false;
    this.readingFromWindow = true;
    console.log('reading from window');
    initialItem = initialItem.replace(/(_)\w+/g, 'blank');
    this.msg = new SpeechSynthesisUtterance();

    this.readingPositive = true;
    this.audio.src = '';
    const voices = window.speechSynthesis.getVoices();
    console.log(
      '🚀 ~ file: read-text.service.ts:402 ~ ReadTextService ~ windowRead ~ voices:',
      voices
    );
    switch (lang) {
      case 'english':
        this.msg.lang = 'en-US';
        this.msg.voice = voices.filter(
          (voice) =>
            voice.lang == 'en-US' &&
            (voice.name.includes('Google') || voice.name.includes('google'))
        )[0];
        if (!this.msg.voice) {
          this.msg.voice = voices.filter((voice) => voice.lang == 'en-US')[0];
        }

        break;
      case 'german':
        this.msg.lang = 'de-DE';
        this.msg.voice = voices.filter((voice) => voice.lang == 'de-DE')[0];
        break;
      case 'french':
        this.msg.lang = 'fr-FR';
        this.msg.voice = voices.filter((voice) => voice.lang == 'fr-FR')[0];
        break;
      case 'spanish':
        this.msg.lang = 'es-Mx';
        this.msg.voice = voices.filter((voice) => voice.lang == 'es-Mx')[0];
        if (!this.msg.voice) {
          this.msg.voice = voices.filter((voice) => voice.lang == 'es-ES')[0];
        }
        break;

      default:
        this.msg.lang = 'en-US';
        this.msg.voice = voices.filter(
          (voice) =>
            voice.lang == 'en-US' &&
            (voice.name.includes('Google') || voice.name.includes('google'))
        )[0];
        if (!this.msg.voice) {
          this.msg.voice = voices.filter((voice) => voice.lang == 'en-US')[0];
        }
        break;
    }

    this.msg.rate = (speed ? speed : 1) - 0.3;

    if (lang == 'polish') {
      this.msg.voice = voices.filter((voice) => voice.lang == 'pl-PL')[0];
      this.msg.lang = 'pl-PL';

      window.speechSynthesis.speak(this.msg);
      this.readingPositive = true;
      this.audioLoading = false;
    } else {
      this.msg.text = initialItem.replaceAll('\\(.*?\\)', '');
      console.log(
        '🚀 ~ file: read-text.service.ts:444 ~ ReadTextService ~ windowRead ~ this.msg:',
        this.msg
      );

      window.speechSynthesis.speak(this.msg);
      this.readingPositive = true;
      this.audioLoading = false;
    }

    this.msg.onend = () => {
      if (this.readingFromWindow) {
        this.readingFromWindow = false;
        this.readingFinishedBearer.next({ readText: initialItem, id });
        this.readingPositive = false;
      }
    };
  }

  readingFinishedListener() {
    return this.readingFinishedBearer.asObservable();
  }

  audioDownloadedListener() {
    return this.audioDownloadedBearer.asObservable();
  }
}
