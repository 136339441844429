import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sentence-word-constructor',
  templateUrl: './sentence-word-constructor.component.html',
  styleUrls: ['./sentence-word-constructor.component.scss'],
})
export class SentenceWordConstructorComponent implements OnInit {
  @Input() meaning;
  @Input() color;

  constructor() {}

  ngOnInit() {}
}
