import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collectable-card',
  templateUrl: './collectable-card.component.html',
  styleUrls: ['./collectable-card.component.css'],
})
export class CollectableCardComponent implements OnInit {
  @Input() collectableData = {
    id: 41,
    name: 'Dooley',
    description:
      'Dooley is a robot. He can fly. He is very friendly and always wants to help others.',
    img: 'assets/collectibles/41.svg',
    rarity: 'Legendary',
    ability: 'speaking backwards',
    skills: {
      strength: ['*', '', '', '', '', ''],
      speed: ['*', '', '', '', '', ''],
      health: ['*', '*', '*', '*', '*', '*'],
      group: 'Planet Alpha',
    },
    level: ['', '', ''],
    numberedLevel: 0,
  };
  @Input() disabled: boolean = false;
  constructor() {}

  ngOnInit(): void {
    this.collectableData.img = this.collectableData.img.replace(
      '/game-elements/space-quiz',
      ''
    );
  }
}
