import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { AlertController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-installation-banner',
  templateUrl: './installation-banner.component.html',
  styleUrls: ['./installation-banner.component.scss']
})
export class InstallationBannerComponent implements OnInit {
  deferredPrompt: any;
  showInstallBanner = false;
  isIos = false;
  isInStandaloneMode = false;
  showIosInstructions: boolean;

  constructor(private platform: Platform, private swUpdate: SwUpdate, private alertController: AlertController) { }

  ngOnInit() {
    this.checkPlatform();
    this.listenForInstallPrompt();
    this.checkIfInstalled();
  }

  checkPlatform() {
    if (this.platform.is('ios')) {
      this.isIos = true;
      if (localStorage.getItem('appInstalled') !== 'true') {
        this.showInstallBanner = true;
      }
    }
  }

  listenForInstallPrompt() {

    window.addEventListener('beforeinstallprompt', (e: any) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.showInstallBanner = true;
    });

    window.addEventListener('appinstalled', (evt) => {
      console.log('INSTALL: Success');
    });

  }

  checkIfInstalled() {
    const installed = localStorage.getItem('appInstalled');
    if (installed === 'true') {
      this.showInstallBanner = false;
    }
  }

  installPwa(): void {
    if (this.isIos) {
      this.showIosInstallInstructions();
    } else if (this.deferredPrompt) {
      this.showInstallBanner = false;
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
          localStorage.setItem('appInstalled', 'true');
        } else {
          console.log('User dismissed the install prompt');
        }
        this.deferredPrompt = null;
      });
    }
  }

  async showIosInstallInstructions() {
    this.showIosInstructions = true;
  }
  closeIosInstructions() {
    this.showIosInstructions = false;
  }
  setAlreadyInstalled() {
    localStorage.setItem('appInstalled', 'true');
    this.closeBanner();
  }
  closeBanner() {
    this.showInstallBanner = false;
    localStorage.setItem('appInstalled', 'true');
  }
}