import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-round-icon-button',
  templateUrl: './round-icon-button.component.html',
  styleUrls: ['./round-icon-button.component.scss'],
})
export class RoundIconButtonComponent implements OnInit {
  @Input() icon: string = 'arrow_back';
  constructor() {}

  ngOnInit(): void {}
}
