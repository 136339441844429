import { S } from '@angular/cdk/keycodes';
import { Injectable } from '@angular/core';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';

@Injectable({
  providedIn: 'root',
})
export class PrizesService {
  prizes = [
    {
      id: 120,
      name: 'Bolt',
      description:
        'Bolt is a wooden horse. He is a toy. He loves playing with children.',
      img: 'assets/collectibles/120.svg',
      rarity: 'Common',
      ability: 'immortality',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 119,
      name: 'Tux',
      description:
        "Tux is a friendly zombie. He isn't very scary. He loves sweets. He is funny.",
      img: 'assets/collectibles/119.svg',
      rarity: 'Rare',
      ability: 'bottomless stomach',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 118,
      name: 'Koda',
      description:
        "Koda is a yeti. She lives in the mountains. She loves cold weather. She isn't scary.",
      img: 'assets/collectibles/118.svg',
      rarity: 'Rare',
      ability: 'time travel',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 117,
      name: 'Lacey',
      description:
        'Lacey is a watermelon. He is also a surfer. He loves surfing on big waves. He is cool.',
      img: 'assets/collectibles/117.svg',
      rarity: 'Common',
      ability: 'perfect hair',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 116,
      name: 'Rozy',
      description:
        'Rozy is a unicorn. She can fly and make a rainbow. She is very cute and friendly.',
      img: 'assets/collectibles/116.svg',
      rarity: 'Legendary',
      ability: 'teleportation',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Zeta',
      },
    },

    {
      id: 115,
      name: 'Misty',
      description:
        "Misty is a unicorn. She can fly. She is very cute, but she isn't friendly. She often gets angry.",
      img: 'assets/collectibles/115.svg',
      rarity: 'Common',
      ability: 'fire eyes',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Sigma',
      },
    },

    {
      id: 114,
      name: 'Bo',
      description:
        'Bo is a unicorn. She can fly and make a rainbow. She is very cute and friendly.',
      img: 'assets/collectibles/114.svg',
      rarity: 'Rare',
      ability: 'magic farts',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 113,
      name: 'Art',
      description:
        'Art is a polar bear. He loves hip-hop. He makes his own beats. He is very good at it.',
      img: 'assets/collectibles/113.svg',
      rarity: 'Common',
      ability: 'horrible singing',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 112,
      name: 'Hunter',
      description:
        'Hunter is a flying squirrel. This means he can jump very far. It look like he is flying.',
      img: 'assets/collectibles/112.svg',
      rarity: 'Common',
      ability: 'flying',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 111,
      name: 'Bud',
      description:
        'Bud is a squirrel. She loves nuts. She lives in the park and climbs trees. She is very cute and friendly.',
      img: 'assets/collectibles/111.svg',
      rarity: 'Common',
      ability: 'mindreading',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 110,
      name: 'Dom',
      description:
        'Dom is a squid, but he is also a scientist. He love to carry out experiments in his secret lab.',
      img: 'assets/collectibles/110.svg',
      rarity: 'Legendary',
      ability: 'control of gravity',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 109,
      name: 'Gus',
      description:
        'Gus is a sloth. He is very lazy. He loves sleeping and eating. He loves sweets.',
      img: 'assets/collectibles/109.svg',
      rarity: 'Rare',
      ability: 'control of pizzas',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 108,
      name: 'Blue',
      description:
        "Blue is a shark. He is still a baby, so he is small. He likes to bite, so don't come near him.",
      img: 'assets/collectibles/108.svg',
      rarity: 'Common',
      ability: 'flying underwater',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Alpha',
      },
    },

    {
      id: 107,
      name: 'Spike',
      description:
        "Spike is a shark. He is very strong. He is dangerous, too. Don't come near him!",
      img: 'assets/collectibles/107.svg',
      rarity: 'Rare',
      ability: 'bad smell',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 106,
      name: 'Mac',
      description:
        'Mac is a seahorse. He is a kind of unusual fish. He Lives deep underwater, and he is cute.',
      img: 'assets/collectibles/106.svg',
      rarity: 'Common',
      ability: 'control of time',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 105,
      name: 'Fletch',
      description:
        'Fletch is a rabbit. He has a cool camera. He loves taking photos with it.',
      img: 'assets/collectibles/105.svg',
      rarity: 'Common',
      ability: 'telekinesis',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 104,
      name: 'Jed',
      description:
        'Jed is a dinosaur. He looks like a bird because he can fly. He likes visiting new places.',
      img: 'assets/collectibles/104.svg',
      rarity: 'Common',
      ability: 'time freeze',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 103,
      name: 'Bam',
      description:
        "Bam is a piranha. He is dangerous, so don't touch him. He isn't very friendly.",
      img: 'assets/collectibles/103.svg',
      rarity: 'Common',
      ability: 'control of monsters',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 102,
      name: 'Kirby',
      description:
        'Kirby is a pig. She has a lot of money and she is very rich. She likes going shopping.',
      img: 'assets/collectibles/102.svg',
      rarity: 'Rare',
      ability: 'treasure finder',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 101,
      name: 'Al',
      description:
        'Al is a pencil. He is a magic pencil because he can walk and talk. He has a backpack.',
      img: 'assets/collectibles/101.svg',
      rarity: 'Common',
      ability: 'failing tests',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Beta',
      },
    },

    {
      id: 100,
      name: 'Spiky',
      description:
        'Spiky is a kangaroo. He is very friendly. She can jump high. He loves taking photos.',
      img: 'assets/collectibles/100.svg',
      rarity: 'Common',
      ability: 'extra big feet',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 99,
      name: 'Kit',
      description:
        'Kit is a kangaroo. She is very cute and friendly. She can jump high. She is very pretty.',
      img: 'assets/collectibles/99.svg',
      rarity: 'Rare',
      ability: 'dance moves',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 98,
      name: 'Dizzy',
      description:
        'Dizzy is a statue, but she is magical. She can walk and talk. She has a sword and scales.',
      img: 'assets/collectibles/98.svg',
      rarity: 'Rare',
      ability: 'remembering everything',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 97,
      name: 'Ike',
      description:
        "Ike is a leopard shark. He isn't dangerous. He likes to swim in near the beach and scare tourists.",
      img: 'assets/collectibles/97.svg',
      rarity: 'Common',
      ability: 'bad ideas',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 96,
      name: 'Zane',
      description:
        'Zane is a monster. He is a friendly monster. He likes jokes. He is very funny.',
      img: 'assets/collectibles/96.svg',
      rarity: 'Common',
      ability: 'magical bellybutton',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 95,
      name: 'Knox',
      description:
        "Knox is a mushroom. He has arms and legs. Don't tough him because he is dangerous.",
      img: 'assets/collectibles/95.svg',
      rarity: 'Common',
      ability: 'two left feet',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 94,
      name: 'Cruz',
      description:
        'Cruz is an octopus. He is also a businessman. He is always busy. He is always on the phone.',
      img: 'assets/collectibles/94.svg',
      rarity: 'Common',
      ability: 'money master',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 93,
      name: 'Duke',
      description:
        'Duke is a piece of sushi. He is special because he can jump and talk. He has superpowers.',
      img: 'assets/collectibles/93.svg',
      rarity: 'Rare',
      ability: 'super intelligence',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 92,
      name: 'Dusty',
      description:
        'Dusty is an otter. He loves water. He spends all his time swimming in the river. He is cute.',
      img: 'assets/collectibles/92.svg',
      rarity: 'Common',
      ability: 'opening portals',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 91,
      name: 'Raze',
      description:
        'Raze is a panda. He has his own music band. He is a great singer. He can play the guitar, too.',
      img: 'assets/collectibles/91.svg',
      rarity: 'Rare',
      ability: 'bad breath',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 90,
      name: 'Bart',
      description:
        'Bart is a crocodile. He is special because he is vegetarian. He loves drinking green tea. ',
      img: 'assets/collectibles/90.svg',
      rarity: 'Rare',
      ability: 'acid breath',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 89,
      name: 'Champ',
      description:
        'Champ is a dinosaur. He is still a baby. He lives in his egg. He loves drinking milk.',
      img: 'assets/collectibles/89.svg',
      rarity: 'Common',
      ability: 'laser eyes',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', '*'],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 88,
      name: 'Digit',
      description:
        'Digit is a dinosaur. He is also a DJ. He plays amazing music. He is very funny.',
      img: 'assets/collectibles/88.svg',
      rarity: 'Legendary',
      ability: 'unfunny jokes',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 87,
      name: 'Fang',
      description:
        'Fang is a dragon. He has yellow wings. She can fly high. He is a friendly dragon.',
      img: 'assets/collectibles/87.svg',
      rarity: 'Common',
      ability: 'soap breath',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 86,
      name: 'Kace',
      description:
        'Kace is a fox. Her fur is soft and beautiful. She lives in the forest and she likes running.',
      img: 'assets/collectibles/86.svg',
      rarity: 'Common',
      ability: 'getting lost',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Delta',
      },
    },

    {
      id: 85,
      name: 'Moxy',
      description:
        "Moxy is a lizard. She has a special collar around her neck. She is friendly, but don't touch her.",
      img: 'assets/collectibles/85.svg',
      rarity: 'Legendary',
      ability: 'super teeth',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 84,
      name: 'Sox',
      description:
        'Sox is a skater, but he is also a games console. He loves playing games.',
      img: 'assets/collectibles/84.svg',
      rarity: 'Rare',
      ability: 'time speed up',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '*', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 83,
      name: 'Yolon',
      description:
        'Yolon is a ghost. He loves dancing. He knows all the cool moves. He loves parties.',
      img: 'assets/collectibles/83.svg',
      rarity: 'Rare',
      ability: 'perfect shoes',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 82,
      name: 'Shoki',
      description:
        'Shoki is a piñata. She is made of paper and has lots of candy inside her belly. She loves parties.',
      img: 'assets/collectibles/82.svg',
      rarity: 'Legendary',
      ability: 'super belly',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 81,
      name: 'Mani',
      description:
        "Mani is a hot dog. He isn't a regular hot dog because he is a superhero. He can fly. ",
      img: 'assets/collectibles/81.svg',
      rarity: 'Legendary',
      ability: 'fire breath',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 80,
      name: 'Tengu',
      description:
        'Tengu is a sandwich. He loves drinking coffee. He also likes tea and hot cocoa.',
      img: 'assets/collectibles/80.svg',
      rarity: 'Rare',
      ability: 'funny jokes',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 79,
      name: 'Miro',
      description:
        'Miro is a bat, but he looks like a cat. He can fly. He wakes up in the evening and stays up all night.',
      img: 'assets/collectibles/79.svg',
      rarity: 'Rare',
      ability: 'seeing through walls',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 78,
      name: 'Bast',
      description:
        'Bast is a cat. He is a special cat because he is a computer. He can do anything online.',
      img: 'assets/collectibles/78.svg',
      rarity: 'Legendary',
      ability: 'super gamer',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 77,
      name: 'Unut',
      description:
        'Unut is a catfish. He lives in the river. He is a great swimmer, and he has long whiskers.',
      img: 'assets/collectibles/77.svg',
      rarity: 'Common',
      ability: 'goldfish memory',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 76,
      name: 'Merit',
      description:
        'Merit is a dog but he is also a knight. He has a sword and a shield. He can protect you.',
      img: 'assets/collectibles/76.svg',
      rarity: 'Common',
      ability: 'perfect aim',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Beta',
      },
    },

    {
      id: 75,
      name: 'Artio',
      description:
        'Artio is a cat. He is also a ninja. He is very quiet but strong. He can do karate.',
      img: 'assets/collectibles/75.svg',
      rarity: 'Rare',
      ability: 'breaking things',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 74,
      name: 'Qvok',
      description:
        'Qvok is a hen. She lays eggs every day. She always sits on them to protect them.',
      img: 'assets/collectibles/74.svg',
      rarity: 'Common',
      ability: 'lazy master',
      skills: {
        strength: ['*', '*', '*', '*', '*', '*'],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 73,
      name: 'Bran',
      description:
        'Bran is a dog detective. He looks for clues and catches criminals. He is very clever.',
      img: 'assets/collectibles/73.svg',
      rarity: 'Common',
      ability: 'map reading',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 72,
      name: 'Riddle',
      description:
        'Riddle is a wizard. He is a special wizard because he is a dog. He can cast magic spells.',
      img: 'assets/collectibles/72.svg',
      rarity: 'Legendary',
      ability: 'control of books',
      skills: {
        strength: ['*', '*', '*', '*', '*', '*'],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 71,
      name: 'Baku',
      description:
        'Baku is a crab. He is a special crab because he loves watching movies.',
      img: 'assets/collectibles/71.svg',
      rarity: 'Common',
      ability: 'forgetting everything',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 70,
      name: 'Olwen',
      description:
        'Olwen is a bear. He loves sleeping. He usually sleeps all day long. He also likes eating.',
      img: 'assets/collectibles/70.svg',
      rarity: 'Rare',
      ability: 'Christmas master',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', '*'],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 69,
      name: 'Emer',
      description:
        "Emer is a dragon. He is very young. He can't breathe fire yet because he is a baby.",
      img: 'assets/collectibles/69.svg',
      rarity: 'Rare',
      ability: 'ice eyes',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 68,
      name: 'Ryoko',
      description:
        'Ryoko is an avocado, but he is also a surfer. He loves big waves and sunny weather.',
      img: 'assets/collectibles/68.svg',
      rarity: 'Common',
      ability: 'master fisher',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Zeta',
      },
    },

    {
      id: 67,
      name: 'Zeir',
      description:
        'Zeir is an avocado, but she looks like a cat. She is green, and she lives in a tree.',
      img: 'assets/collectibles/67.svg',
      rarity: 'Rare',
      ability: 'flowery smell',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 66,
      name: 'Vaak',
      description:
        'Vaak is a llama. He does yoga. He is always relaxed, and he is never stressed.',
      img: 'assets/collectibles/66.svg',
      rarity: 'Common',
      ability: 'super senses',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 65,
      name: 'Yong',
      description:
        'Yong is an alpaca. He lives in a box because he can become small or big.',
      img: 'assets/collectibles/65.svg',
      rarity: 'Rare',
      ability: 'master hiding',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 64,
      name: 'Zyphon',
      description:
        'Zyphon is an alien. He comes from a planet far away. He has a spaceship, and he loves sweets.',
      img: 'assets/collectibles/64.svg',
      rarity: 'Legendary',
      ability: 'super eater',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 63,
      name: 'Paku',
      description:
        'Paku is a legendary dragon. He can fly and he can breathe fire.',
      img: 'assets/collectibles/63.svg',
      rarity: 'Legendary',
      ability: 'time manipulation',
      skills: {
        strength: ['*', '*', '*', '*', '*', '*'],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 62,
      name: 'Dolly',
      description:
        'Dolly is a cat. But she is a special cat because she is a robot. She is cute.',
      img: 'assets/collectibles/62.svg',
      rarity: 'Common',
      ability: 'super ears',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 61,
      name: 'Fett',
      description:
        'Fett is a cat. He is also a businessman. He has a cool phone and a suitcase.',
      img: 'assets/collectibles/61.svg',
      rarity: 'Common',
      ability: 'perfect suits',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 60,
      name: 'Anwar',
      description:
        'Anwar is a chameleon. He lives on a tree and loves eating flies.',
      img: 'assets/collectibles/60.svg',
      rarity: 'Rare',
      ability: 'wall climbing',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 59,
      name: 'Conny',
      description:
        'Conny is a cat. She loves cool clothes and always wears sunglasses.',
      img: 'assets/collectibles/59.svg',
      rarity: 'Rare',
      ability: 'super shopper',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 58,
      name: 'Ervar',
      description:
        'Ervar is a frog. He lives in a small pond. He can catch flies with his tongue.',
      img: 'assets/collectibles/58.svg',
      rarity: 'Common',
      ability: 'stretchy arms',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 57,
      name: 'Rinc',
      description:
        'Rinc is a dog. He loves sports. He is the best player on his team.',
      img: 'assets/collectibles/57.svg',
      rarity: 'Common',
      ability: "teacher's pet",
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 56,
      name: 'Kango',
      description:
        'Kango is a lion. He is the king of all animals. His roar is very loud.',
      img: 'assets/collectibles/56.svg',
      rarity: 'Common',
      ability: 'control of animals',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Omega',
      },
    },

    {
      id: 55,
      name: 'Bali',
      description:
        'Bali is a sea cow. She is very big, but she is friendly. She loves swimming in the ocean.',
      img: 'assets/collectibles/55.svg',
      rarity: 'Legendary',
      ability: 'mind control',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Omega',
      },
    },

    {
      id: 54,
      name: 'Abner',
      description:
        'Abner is a dog. She loves eating bones and playing with her ball outside.',
      img: 'assets/collectibles/54.svg',
      rarity: 'Common',
      ability: 'hypnosis',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 53,
      name: 'Conlan',
      description:
        "Conlan is a mushroom. He can be dangerous, so don't touch him if you find him in the forest.",
      img: 'assets/collectibles/53.svg',
      rarity: 'Rare',
      ability: 'speaking to trees',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 52,
      name: 'Orli',
      description:
        "Orli is a dog. He is very smart, and he can ride a scooter. It's cool.",
      img: 'assets/collectibles/52.svg',
      rarity: 'Common',
      ability: 'vehicle transformation',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 51,
      name: 'Dima',
      description:
        'Dima is a unicorn. She is white, blue, pink, and yellow. She loves her rainbow.',
      img: 'assets/collectibles/51.svg',
      rarity: 'Legendary',
      ability: 'super skiing',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 50,
      name: 'Kaveh',
      description:
        'Kaveh is a koala. He lives in Australia and loves sleeping and eating leaves.',
      img: 'assets/collectibles/50.svg',
      rarity: 'Common',
      ability: 'super nose',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 49,
      name: 'Archi',
      description:
        'Archi is a dinosaur. He has very short arms, but he can run fast.',
      img: 'assets/collectibles/49.svg',
      rarity: 'Common',
      ability: 'horrible dancing',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 48,
      name: 'Partho',
      description:
        'Partho is a crab. He lives on the beach. He has eight legs and two claws.',
      img: 'assets/collectibles/48.svg',
      rarity: 'Rare',
      ability: 'spider vision',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Theta',
      },
    },

    {
      id: 47,
      name: 'Sinha',
      description:
        'Sinha is a dog. He lives in Japan, and he can do karate very well.',
      img: 'assets/collectibles/47.svg',
      rarity: 'Rare',
      ability: 'control of dragons',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 46,
      name: 'Jomei',
      description:
        'Jomei is a friendly monster. He lives in the mountains. He loves cold weather and snow.',
      img: 'assets/collectibles/46.svg',
      rarity: 'Legendary',
      ability: 'crazy hair',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 45,
      name: 'Heron',
      description:
        'Heron is a hamster. He Loves eating and sleeping. He can run very fast.',
      img: 'assets/collectibles/45.svg',
      rarity: 'Common',
      ability: 'control of bugs',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 44,
      name: 'Isa',
      description:
        'Isa is a koala. She is a mother. She has a little baby daughter. She loves her very much.',
      img: 'assets/collectibles/44.svg',
      rarity: 'Common',
      ability: 'control of bears',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 43,
      name: 'Kondo',
      description:
        'Kondo is a snail. He is very slow. He carries his home on his back.',
      img: 'assets/collectibles/43.svg',
      rarity: 'Rare',
      ability: 'control of snails',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 42,
      name: 'Arell',
      description:
        'Arell is a planet, but she looks like a cat. She is pink, and she loves meeting astronauts.',
      img: 'assets/collectibles/42.svg',
      rarity: 'Rare',
      ability: 'animal transformation',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 41,
      name: 'Dooley',
      description:
        'Dooley is a robot. He can fly. He is very friendly and always wants to help others.',
      img: 'assets/collectibles/41.svg',
      rarity: 'Legendary',
      ability: 'speaking backwards',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Alpha',
      },
    },

    {
      id: 40,
      name: 'Makai',
      description:
        'Makai is a penguin, but she hates cold weather. She would like to live in Africa.',
      img: 'assets/collectibles/40.svg',
      rarity: 'Common',
      ability: 'control of the weather',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 39,
      name: 'Harb',
      description:
        'Harb is a guinea pig. He loves exercising. He is very strong.',
      img: 'assets/collectibles/39.svg',
      rarity: 'Rare',
      ability: 'super strength',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 38,
      name: 'Laran',
      description:
        'Laran is a red panda. He lives in China, and he loves eating noodles.',
      img: 'assets/collectibles/38.svg',
      rarity: 'Common',
      ability: 'crazy rhymes',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 37,
      name: 'Ariki',
      description:
        'Ariki is a robot. He is very intelligent. He can speak twenty different languages.',
      img: 'assets/collectibles/37.svg',
      rarity: 'Rare',
      ability: 'control of screens',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 36,
      name: 'Floki',
      description:
        'Floki is a beaver. He lives near the river. He has very sharp teeth that can cut down trees.',
      img: 'assets/collectibles/36.svg',
      rarity: 'Rare',
      ability: 'speaking to animals',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 35,
      name: 'Raiko',
      description:
        'Raiko is a cat. She loves playing hide and seek. She likes to hide in boxes.',
      img: 'assets/collectibles/35.svg',
      rarity: 'Legendary',
      ability: 'super sleep',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 34,
      name: 'Kage',
      description:
        'Kage is a bee. He works very hard and is always busy. He can make sweet honey.',
      img: 'assets/collectibles/34.svg',
      rarity: 'Common',
      ability: 'master tracking',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 33,
      name: 'Delano',
      description:
        'Delano is a doughnut. He has pink icing and sprinkles. He loves sweet things.',
      img: 'assets/collectibles/33.svg',
      rarity: 'Rare',
      ability: 'judo master',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 32,
      name: 'Kerrim',
      description:
        'Kerrim is a dinosaur. He looks scary, but he is very friendly. He loves eating bananas.',
      img: 'assets/collectibles/32.svg',
      rarity: 'Common',
      ability: 'control of fire',
      skills: {
        strength: ['*', '*', '*', '*', '*', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Omega',
      },
    },

    {
      id: 31,
      name: 'Wyne',
      description:
        'Wyne is a cow. She lives on a farm, and she gives milk. She loves eating grass.',
      img: 'assets/collectibles/31.svg',
      rarity: 'Common',
      ability: 'control of pigs',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 30,
      name: 'Amit',
      description:
        'Amit is a bear. He loves spending time in the forest. He can walk all day long.',
      img: 'assets/collectibles/30.svg',
      rarity: 'Common',
      ability: 'bottomless pocket',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 29,
      name: 'Rafiq',
      description:
        'Rafiq is a llama. He is white and very fluffy. He has a very long neck and short legs.',
      img: 'assets/collectibles/29.svg',
      rarity: 'Rare',
      ability: 'control of unicorns',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', '*'],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 28,
      name: 'Ortwin',
      description:
        'Ortwin is a turtle. He is green, and he has a hard shell. It is his home, and he can hide in it.',
      img: 'assets/collectibles/28.svg',
      rarity: 'Common',
      ability: 'karate master',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 27,
      name: 'Reu',
      description:
        'Reu is a dog. He has very big ears and cool sunglasses. He likes playing with cats.',
      img: 'assets/collectibles/27.svg',
      rarity: 'Common',
      ability: 'super reading',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 26,
      name: 'Elvie',
      description:
        "Elvie is a sloth. She is a little lazy. She likes sleeping and relaxing. She doesn't like running.",
      img: 'assets/collectibles/26.svg',
      rarity: 'Legendary',
      ability: 'control of storm',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Gamma',
      },
    },

    {
      id: 25,
      name: 'Aimee',
      description:
        "Aimee is an owl. She doesn't sleep at night. She is awake all night long, and she goes to sleep in the morning.",
      img: 'assets/collectibles/25.svg',
      rarity: 'Common',
      ability: 'super writing',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 24,
      name: 'Sima',
      description:
        'Sima is an aksolotl. She is like a lizard, but she lives in water. She is pink and very pretty.',
      img: 'assets/collectibles/24.svg',
      rarity: 'Legendary',
      ability: 'disappearance',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '*', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 23,
      name: 'Ervin',
      description:
        'Ervin is a fish. Sometimes he is small, and sometimes he is big. He is very big when he is scared',
      img: 'assets/collectibles/23.svg',
      rarity: 'Common',
      ability: 'transformation ',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 22,
      name: 'Pin',
      description:
        'Pin is a sumo wrestler. He is very big and strong. He is funny and he likes laughing.',
      img: 'assets/collectibles/22.svg',
      rarity: 'Rare',
      ability: 'control of bricks',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 21,
      name: 'Wynn',
      description:
        'Wynn is a duck. She lives near a river. She loves water and she can swim very well.',
      img: 'assets/collectibles/21.svg',
      rarity: 'Common',
      ability: 'losing things',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 20,
      name: 'Aakal',
      description:
        'Aakal is a dog. He is very special because he is a dog king. He has a beautiful gold crown.',
      img: 'assets/collectibles/20.svg',
      rarity: 'Common',
      ability: 'control of teachers',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Sigma',
      },
    },

    {
      id: 19,
      name: 'Netis',
      description:
        "Netis is a hedgehog. He lives in the forest. Don't touch him because he has needles on his back.",
      img: 'assets/collectibles/19.svg',
      rarity: 'Legendary',
      ability: 'ant vision',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 18,
      name: 'Waiz',
      description:
        'Waiz is a plant. He is a cactus plant. He is green, and he has two brothers: Laiz and Taiz.',
      img: 'assets/collectibles/18.svg',
      rarity: 'Rare',
      ability: 'monkey brain',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Kappa',
      },
    },

    {
      id: 17,
      name: 'Ralph',
      description:
        'Ralph is a baby. But he is very smart, and he is a businessman. He is wearing a blue suit.',
      img: 'assets/collectibles/17.svg',
      rarity: 'Rare',
      ability: 'control of babies',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 16,
      name: 'Mika',
      description:
        "Mika is a dinosaur. She isn't dangerous because she only eats plants. She has a long tail.",
      img: 'assets/collectibles/16.svg',
      rarity: 'Common',
      ability: 'control of electricity',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Alpha',
      },
    },

    {
      id: 15,
      name: 'Zeki',
      description:
        'Zeki is a robot. He looks like a panda. He loves eating and playing with bamboo sticks.',
      img: 'assets/collectibles/15.svg',
      rarity: 'Common',
      ability: 'homework master',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 14,
      name: 'Jabir',
      description:
        'Jabir is a monkey. He can climb trees. He can dance, too! He is a very good dancer.',
      img: 'assets/collectibles/14.svg',
      rarity: 'Common',
      ability: 'riding a shark',
      skills: {
        strength: ['*', '*', '*', '*', '*', '*'],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 13,
      name: 'Michiko',
      description:
        'Michiko is a cat. He is a special cat because he likes elegant clothes. He always looks nice.',
      img: 'assets/collectibles/13.svg',
      rarity: 'Common',
      ability: 'eagle vision',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Alpha',
      },
    },

    {
      id: 12,
      name: 'Daly',
      description:
        'Daly is a sloth. She can ride a bike, but she is very lazy. She always wants to go to sleep.',
      img: 'assets/collectibles/12.svg',
      rarity: 'Common',
      ability: 'super speed',
      skills: {
        strength: ['*', '*', '*', '*', '*', '*'],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 11,
      name: 'Tami',
      description:
        'Tami is a zebra. She is black and white. She can run very fast, and she loves grass.',
      img: 'assets/collectibles/11.svg',
      rarity: 'Common',
      ability: 'invisibility',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '*', '*', '*', '*', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Theta',
      },
    },

    {
      id: 10,
      name: 'Echo',
      description:
        'Echo is a polar bear. He is white, and he has a blue computer. He loves playing games on it.',
      img: 'assets/collectibles/10.svg',
      rarity: 'Common',
      ability: 'hacking',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '*', '*', '*'],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 9,
      name: 'Dyso',
      description:
        "Dyso is a fish. He doesn't like noise. He is very angry when others are noisy.",
      img: 'assets/collectibles/9.svg',
      rarity: 'Rare',
      ability: 'control of snakes',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Zeta',
      },
    },

    {
      id: 8,
      name: 'Jella',
      description:
        'Jella is an octopus. She is red, and she lives in the ocean. She has eight legs, and she is very funny.',
      img: 'assets/collectibles/8.svg',
      rarity: 'Rare',
      ability: 'language master',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '*', '*'],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 7,
      name: 'Kido',
      description:
        'Kido is a little dragon. He is a baby, so he is always hungry. He loves eating pasta.',
      img: 'assets/collectibles/7.svg',
      rarity: 'Legendary',
      ability: 'control of students',
      skills: {
        strength: ['*', '', '', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 6,
      name: 'Vega',
      description:
        'Vega is a cat. She likes playing with other cats outside. She loves eating fish and drinking milk.',
      img: 'assets/collectibles/6.svg',
      rarity: 'Common',
      ability: 'control of parents',
      skills: {
        strength: ['*', '*', '*', '*', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 5,
      name: 'Jabor',
      description:
        'Jabor is a robot. He looks like a monkey. He is very fast, and he can jump very high.',
      img: 'assets/collectibles/5.svg',
      rarity: 'Legendary',
      ability: 'walking through walls',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '*', '*', '', ''],
        group: 'Planet Epsilon',
      },
    },

    {
      id: 4,
      name: 'Kove',
      description:
        'Kove is an octopus. He is a builder, too! He can build a house and fix a broken chair.',
      img: 'assets/collectibles/4.svg',
      rarity: 'Rare',
      ability: 'fixing things',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '*', '*', '', '', ''],
        health: ['*', '*', '*', '', '', ''],
        group: 'Planet Delta',
      },
    },

    {
      id: 3,
      name: 'Kehsi',
      description:
        'Kehsi is a friendly monster. He is green, and he has a big mouth and two horns.',
      img: 'assets/collectibles/3.svg',
      rarity: 'Rare',
      ability: 'control of earthquakes',
      skills: {
        strength: ['*', '*', '*', '*', '*', '*'],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Gamma',
      },
    },

    {
      id: 2,
      name: 'Pax',
      description:
        'Pax is a duck. He can swim, and he can fly. He is brown and green. He is very fast.',
      img: 'assets/collectibles/2.svg',
      rarity: 'Common',
      ability: 'super healing',
      skills: {
        strength: ['*', '*', '*', '', '', ''],
        speed: ['*', '*', '', '', '', ''],
        health: ['*', '', '', '', '', ''],
        group: 'Planet Beta',
      },
    },

    {
      id: 1,
      name: 'Nona',
      description:
        "Nona is a bird. She can fly. She lives near the beach. She likes water, but she can't swim.",
      img: 'assets/collectibles/1.svg',
      rarity: 'Rare',
      ability: 'breathing underwater',
      skills: {
        strength: ['*', '*', '', '', '', ''],
        speed: ['*', '', '', '', '', ''],
        health: ['*', '*', '', '', '', ''],
        group: 'Planet Alpha',
      },
    },
  ];
  planetsColors = [
    { name: 'Planet Theta', bgColor: '#0d47a1', textColor: '#82b1ff' },
    { name: 'Planet Sigma', bgColor: '#bf360c', textColor: '#ff9e80' },
    { name: 'Planet Kappa', bgColor: '#880e4f', textColor: '#ff80ab' },
    { name: 'Planet Zeta', bgColor: '#006064', textColor: '#84ffff' },
    { name: 'Planet Gamma', bgColor: '#01579b', textColor: '#80d8ff' },
    { name: 'Planet Alpha', bgColor: '#f57f17', textColor: '#ffff8d' },
    { name: 'Planet Beta', bgColor: '#311b92', textColor: '#b388ff' },
    { name: 'Planet Delta', bgColor: '#33691e', textColor: '#ccff90' },
    { name: 'Planet Omega', bgColor: '#1a237e', textColor: '#8c9eff' },
    { name: 'Planet Epsilon', bgColor: '#004d40', textColor: '#a7ffeb' },
  ];
  constructor(
    private userDetailsService: UserDetailsService,
    private helpers: HelpersService
  ) {}
  getCategorizedPrizes() {
    let planets = [];
    planets = this.prizes.map((prize) => {
      return prize.skills.group;
    });
    planets = [...new Set(planets)];
    planets = planets.map((planet) => {
      return { name: planet };
    });
    planets.forEach((planet) => {
      // planet.commonColor = "#412280";
      // planet.rareColor = "#361c6b";
      // planet.legendaryColor = "#2d1759";
      planet.prizes = this.prizes.filter(
        (prize) => prize.skills.group == planet.name
      );
      planet.prizes.forEach((prize) => {
        prize.level = this.checkCollectablesLevel(prize.id).level;
        prize.numberedLevel = this.checkCollectablesLevel(
          prize.id
        ).numberedLevel;
        // prize.numberedLevel = 1;
      });
    });
    console.log(
      '🚀 ~ file: prizes.service.ts ~ line 1934 ~ PrizesService ~ getCategorizedPrizes ~ planets',
      planets
    );
    planets.forEach((planet) => {
      const planetColors = this.planetsColors.find(
        (_planet) => _planet.name == planet.name
      );
      if (planetColors) {
        planet.bgColor = planetColors.bgColor;
        planet.nameColor = planetColors.textColor;
      }
    });
    const usersPrizes =
      this.userDetailsService.getUserDetails()?.userData.collectables;
    console.log(
      '🚀 ~ file: prizes.service.ts:1985 ~ PrizesService ~ getCategorizedPrizes ~ usersPrizes:',
      usersPrizes
    );
    planets = this.helpers.sortArrayByProperty(planets, 'name', 1);
    let usersPlanet = this.helpers.clone(planets[0]);
    usersPlanet.prizes = usersPrizes;
    usersPlanet.prizes.forEach((prize) => {
      prize.level = this.checkCollectablesLevel(prize.id).level;
      prize.numberedLevel = this.checkCollectablesLevel(prize.id).numberedLevel;
      // prize.numberedLevel = 1;
    });
    usersPlanet.prizes = this.helpers.removeDuplicatesObjbyId(
      usersPlanet.prizes
    );
    // usersPlanet.prizes.forEach((prize) => {
    //   if (prize.numberedLevel == 0 || !prize.numberedLevel) {
    //     prize.numberedLevel = 1;
    //     prize.level = ['*', '', ''];
    //   }
    // });
    usersPlanet.name = 'My planet';
    planets.unshift(usersPlanet);
    return planets;
  }

  checkCollectablesLevel(id) {
    let level = ['', '', ''];
    let numberedLevel = 0;
    const usersCollectables =
      this.userDetailsService.getUserDetails()?.userData?.collectables;
    if (usersCollectables) {
      const filteredCollectables = usersCollectables.filter(
        (collectable) => collectable.id === id
      );
      if (filteredCollectables.length == 0) {
        level = ['', '', ''];
      }
      if (filteredCollectables.length == 1) {
        level = ['*', '', ''];
        numberedLevel = 1;
      }
      if (filteredCollectables.length == 2) {
        level = ['*', '*', ''];
        numberedLevel = 2;
      }
      if (filteredCollectables.length == 3) {
        level = ['*', '*', '*'];
        numberedLevel = 3;
      }
    }
    return { level: level, numberedLevel: numberedLevel };
  }
}
