import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {
  private spinnerTimeouts: any[] = [];
  private spinners: HTMLIonLoadingElement[] = [];
  spinnerMessage = 'Trwa wczytywanie...';
  private spinner: HTMLIonLoadingElement;

  constructor(private loadingCtrl: LoadingController, private router: Router) {
    // this.router.events.subscribe((event) => {
    //   console.log(
    //     '🚀 ~ file: spinner.service.ts:34 ~ SpinnerService ~ this.router.events.subscribe ~ event:',
    //     event
    //   );
    //   if (event instanceof NavigationStart) {
    //     this.showSpinner();
    //   }
    //   if (
    //     event instanceof NavigationEnd ||
    //     event instanceof NavigationCancel ||
    //     event instanceof NavigationError
    //   ) {
    //     console.log('NavigationEnd detected'); // Debug log
    //     this.hideSpinner();
    //   }
    // });
  }

  async showSpinner() {
    const spinner = await this.loadingCtrl.create({
      message: this.spinnerMessage,
      cssClass: 'dark-backdrop',
      spinner: 'bubbles',
    });
    this.spinners.push(spinner);

    const timeout = setTimeout(() => {
      this.hideSpinner(spinner);
      console.warn('A spinner dismissed after 5 seconds due to timeout.');
    }, 5000);
    this.spinnerTimeouts.push(timeout);

    spinner.present();
  }

  hideSpinner(spinnerToHide?: HTMLIonLoadingElement) {
    console.log('Attempting to hide spinner'); // Debug log

    if (!spinnerToHide && this.spinners.length) {
      // Take the latest spinner if no specific spinner is provided
      spinnerToHide = this.spinners[this.spinners.length - 1];
    }

    const index = this.spinners.indexOf(spinnerToHide);
    if (index !== -1) {
      if (this.spinnerTimeouts[index]) {
        clearTimeout(this.spinnerTimeouts[index]);
      }
      spinnerToHide.dismiss();
      this.spinners.splice(index, 1);
      this.spinnerTimeouts.splice(index, 1);
    } else {
      console.warn('Attempted to hide a spinner that was not found.');
    }
  }
}
