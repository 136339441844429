import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-user-avatar-small',
  templateUrl: './user-avatar-small.component.html',
  styleUrls: ['./user-avatar-small.component.css'],
})
export class UserAvatarSmallComponent implements OnInit {
  @Input() name: string;
  @Input() avatar: string;
  @Input() selected: boolean;
  @Output() addTaskClicked = new EventEmitter();

  constructor() {}

  ngOnInit(): void {
    this.selected;
    console.log(
      '🚀 ~ file: user-avatar-small.component.ts ~ line 17 ~ UserAvatarSmallComponent ~ ngOnInit ~ this.selected',
      this.selected
    );
  }
}
