import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TimerService } from '../../services/timer.service';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss'],
  providers: [TimerService],
})
export class TimerComponent implements OnInit, OnDestroy {
  @Input() command: 'start' | 'stop' | 'reset';
  @Output() resetGame = new EventEmitter();
  private subscription: Subscription;
  timerString: string;

  constructor(private timer: TimerService) {}
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if (changes.command.currentValue === 'start') {
      this.start();
    }
    if (changes.command.currentValue === 'stop') {
      this.stop();
    }
    if (changes.command.currentValue === 'reset') {
      this.reset();
    }
  }
  ngOnInit() {
    this.subscription = this.timer.timer$.subscribe(
      (time) => (this.timerString = time)
    );
  }

  start() {
    this.timer.start();
  }

  stop() {
    this.timer.stop();
  }

  reset() {
    this.timer.reset();
    this.resetGame.emit();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
