import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { SpeechToTextService } from 'src/app/shared/services/speech-recognition.service';
import toWav from 'audiobuffer-to-wav';
import { NavParams, ToastController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ReadTextService } from 'src/app/shared/services/read-text.service';
import { QuizService } from "../quiz.service";

@Component({
  selector: 'app-speech-recognition',
  templateUrl: './speech-recognition.component.html',
  styleUrls: ['./speech-recognition.component.scss'],
})
export class SpeechRecognitionComponent implements OnInit {
  private mediaRecorder: MediaRecorder;
  private recordedChunks: BlobPart[] = [];
  unrecognizedCount: number = 0;
  recordingStartedTime: number = 0;
  recordingDuration: number = 0;
  speechRecognitionAvalible = false;
  @Input() expectedResult;
  @Input() chooseFromPossibilities = [];
  @Input() onChat = false;
  reading = false;
  @Output() correctAnswer = new EventEmitter();
  data;
  transcription: string = '';
  @Input() difficulty: 'easy' | 'medium' | 'hard' = 'medium';
  speaking: boolean = false;
  sub: any;
  textToRead: any;
  notUnderstood: boolean = false;
  rateLettersPerSecond: number;
  estimatedReadingTime: number;
  timeout;
  speakingNumber = 2
  stream: MediaStream;
  checkingAnswer: boolean = false;
  speakingPossibleOverride: boolean = false;
  currentAudioFile: File;
  constructor(
    public speechToTextService: SpeechToTextService,
    private ref: ChangeDetectorRef,
    private modalCtrl: ModalController,
    public readTextService: ReadTextService,
    private toastController: ToastController,
    private quizService: QuizService,
  ) { }
  ngOnInit(): void {
    this.textToRead = this.expectedResult;

    this.rateLettersPerSecond = 10;

    console.log("🚀 ~ file: speech-recognition.component.ts:62 ~ SpeechRecognitionComponent ~ ngOnInit ~ this.textToRead.length:", this.textToRead.length)
    this.estimatedReadingTime =
      this.estimatedReadingTime = (this.textToRead.length / this.rateLettersPerSecond) + 1.2;
    console.log("🚀 ~ file: speech-recognition.component.ts:64 ~ SpeechRecognitionComponent ~ ngOnInit ~  this.estimatedReadingTime:", this.estimatedReadingTime)

    // console.log(
    //   '🚀 ~ file: answer-summary-modal.component.ts:36 ~ AnswerSummaryModalComponent ~ ngOnInit ~ this.data:',
    //   this.data
    // );
    // this.timeout = setTimeout(() => {
    //   this.speakingPossible = true;
    //   this.readTextService.readingPositive = false;
    // }, this.estimatedReadingTime * 1000);
    this.sub = this.speechToTextService
      .recognitionResultListener()
      .subscribe((result) => {
        if (this.onChat && result == this.expectedResult) {
          this.correctAnswer.emit();
          return;
        } else if (this.onChat && result !== this.expectedResult) {
          alert("Sorry, I didn't get that. Can you say it again?");
        }
      });
  }

  startSpeaking() {
    if (
      (!this.checkingAnswer && !this.readTextService.readingPositive)
    ) {
      this.speakingPossibleOverride = true;
      const interval = setInterval(() => {
        if (this.speakingNumber > 0) {
          this.speakingNumber--;
        }
        if (this.speakingNumber == 0) {
          clearInterval(interval);
          this.speaking = true;
          this.speakingPossibleOverride = false;
        }

      }, 1000);

      if (this.speechRecognitionAvalible) {
        this.startInternalRecording();
      } else {
        this.startRecording();
      }
    }
  }
  stopSpeaking() {
    this.speakingNumber = 2;
    setTimeout(() => {
      if (this.checkingAnswer) {
        this.checkingAnswer = false;
        // this.presentToast('bottom');
        this.compareAudioDuration(this.currentAudioFile, this.estimatedReadingTime)
          .then(isWithinTolerance => {
            console.log(isWithinTolerance); // true if within 1 seconds, false otherwise
            if (!isWithinTolerance) {
              // this.presentToast('bottom');
              this.speechToTextService.checkSimilarity(
                this.expectedResult,
                '',
                this.difficulty,
                'true',
                this.onChat ? true : false
              );
            }
            if (isWithinTolerance) {
              this.speechToTextService.checkSimilarity(
                this.expectedResult,
                this.expectedResult,
                this.difficulty,
                'true',
                this.onChat ? true : false
              );
            }
          })
          .catch(error => {
            console.error(error);
          });
      }
    }, 6000);
    this.speaking = false;
    if (this.speechRecognitionAvalible) {
      this.stopInternalRecording();
    } else {
      this.stopRecording();
    }
  }
  startInternalRecording(): void {
    this.speechToTextService.startBrowserRecognition(
      this.expectedResult,
      null,
      'english',
      this.difficulty
    );
  }
  changeValue(event) {
    this.speechRecognitionAvalible =
      event.detail.value === 'true' ? true : false;
  }
  stopInternalRecording(): void {
    this.speechToTextService.stopBrowserRecognition();
  }

  async startRecording(): Promise<void> {
    try {
      // Check if we need to re-acquire the media stream
      if (
        !this.stream ||
        !this.stream.getTracks().find((track) => track.readyState === 'live')
      ) {
        this.stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

      }

      this.recordingStartedTime = Date.now();

      this.mediaRecorder = new MediaRecorder(this.stream);
      this.mediaRecorder.ondataavailable = (event) => {
        this.recordedChunks.push(event.data);
      };

      this.mediaRecorder.start();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  }

  async stopRecording(): Promise<void> {
    this.checkingAnswer = true;
    this.recordingDuration = (Date.now() - this.recordingStartedTime) / 1000;

    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.recordedChunks);
        const audioBuffer = await this.blobToAudioBuffer(audioBlob);
        const wavBuffer = toWav(audioBuffer);
        const wavBlob = new Blob([wavBuffer], { type: 'audio/wav' });

        this.recordedChunks = [];
        this.sendAudioToServer(wavBlob);

        // Stop and release the media stream only when completely done
        if (this.stream) {
          this.stream.getTracks().forEach((track) => track.stop());
          this.stream = null;
        }

        this.mediaRecorder = null;
      };

      this.mediaRecorder.stop();
      setTimeout(() => {
        this.resetAudioRoute();
      }, 1000);
    }
    this.mediaRecorder = null;

    setTimeout(() => {
      this.resetAudioRoute();
    }, 1000);
  }
  resetAudioRoute() {
    const audioCtx = new window.AudioContext();
    const oscillator = audioCtx.createOscillator();
    oscillator.type = 'sine';
    oscillator.frequency.setValueAtTime(0, audioCtx.currentTime);
    oscillator.connect(audioCtx.destination);
    oscillator.start();
    oscillator.stop(audioCtx.currentTime + 0.1);
    if (this.mediaRecorder) {
      this.mediaRecorder.onstop = null;
      this.mediaRecorder.ondataavailable = null;
      this.mediaRecorder = null;
    }

    if (this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
    }
  }
  async blobToAudioBuffer(blob: Blob): Promise<AudioBuffer> {
    try {
      const arrayBuffer = await blob.arrayBuffer();
      const audioContext = new AudioContext();
      try {
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        return audioBuffer;
      } catch (decodeError) {
        console.error('Error decoding audio data:', decodeError);
        throw decodeError; // Re-throw the error after logging it
      }
    } catch (blobError) {
      console.error('Error converting blob to array buffer:', blobError);
      throw blobError; // Re-throw the error after logging it
    }
  }

  sendAudioToServer(audioBlob: Blob): void {
    let audioFile = new File([audioBlob], 'audio.wav', { type: 'audio/wav' });
    this.currentAudioFile = audioFile;
    // this.speechToTextService.transcribe(audioFile);
    this.speechToTextService.transcribe(audioFile).subscribe((response) => {
      console.log(
        '🚀 ~ file: speech-recognition.component.ts:100 ~ SpeechRecognitionComponent ~ this.speechToTextService.transcribe ~ response:',
        response
      );
      console.log('Transcription:', response.text);
      if (
        !this.speaking &&
        !this.readTextService.readingPositive &&
        this.checkingAnswer
      ) {
        this.transcription = response.text;
        this.speechToTextService.speakingTry++;
        if (this.expectedResult) {
          this.speechToTextService.checkSimilarity(
            this.expectedResult,
            response.text,
            this.difficulty,
            'true',
            this.onChat ? true : false
          );
          this.ref.detectChanges();
          setTimeout(() => {
            this.checkingAnswer = false;
          }, 600);
        }
      }
    });
    console.log(
      '🚀 ~ file: speech-recognition.component.ts:83 ~ SpeechRecognitionComponent ~ this.speechToTextService.transcribe ~ expectedResult:',
      this.expectedResult
    );
    audioFile = null;
  }
  compareAudioDuration(audioFile, estimatedReadingTime) {
    console.log("🚀 ~ file: speech-recognition.component.ts:286 ~ SpeechRecognitionComponent ~ compareAudioDuration ~ estimatedReadingTime:", estimatedReadingTime)
    return new Promise((resolve, reject) => {
      // Create an audio element
      let audio = new Audio();
      console.log("🚀 ~ file: speech-recognition.component.ts:290 ~ SpeechRecognitionComponent ~ returnnewPromise ~ audio:", audio.duration)

      // Event listener for when the audio metadata is loaded
      audio.onloadedmetadata = function () {
        // Calculate the duration difference
        let durationDifference = Math.abs(audio.duration - estimatedReadingTime);

        // Check if the difference is within 1 seconds tolerance
        resolve(durationDifference <= 1);
      };

      // Event listener for error handling
      audio.onerror = function () {
        reject('Error loading audio file');
      };

      // Read the audio file
      audio.src = URL.createObjectURL(audioFile);
    });
  }
  async presentToast(position: 'top' | 'middle' | 'bottom') {
    let duration = 2000;
    if (this.unrecognizedCount == 1) {
      duration = 1000;
    }
    if (this.unrecognizedCount == 2) {
      duration = 500;

    }
    const toast = await this.toastController.create({
      message: 'Nie udało się rozpoznać mowy. Spróbuj ponownie. Jeśli problem się powtarza sprawdź uprawnienia mikrofonu.',
      duration: duration,
      position: position,
    });
    toast.present();
    this.unrecognizedCount++
    if (this.unrecognizedCount > 0) {
      this.quizService.flipSpeakingAndSentence()
    }
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
}
