import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserDetailsService } from './user-details.service';
import { Observable } from 'rxjs';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { RtcService } from 'src/app/shared/services/rtc.service';
import { version } from '../../../environments/version';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { LiveGameCommunicatorService } from 'src/app/shared/services/live-game-communicator.service';

@Component({
  selector: 'app-progress',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  userDetails;
  version = version;
  sub: any;
  constructor(
    private router: Router,
    public userDetailsService: UserDetailsService,
    private helpers: HelpersService,
    private rtcService: RtcService,
    private auth: AuthenticationService,
    private liveCommunicationService: LiveGameCommunicatorService
  ) { }

  ngOnInit() {
    this.sub = this.userDetailsService
      .userDetailsListener()
      .subscribe((userDetails) => {
        if (userDetails) {
          this.userDetails = userDetails;
          console.log(
            '🚀 ~ file: profile.component.ts:35 ~ ProfilesComponent ~ ngOnInit ~ this.userDetails:',
            this.userDetails
          );

        }
        if (this.userDetails.userData.ageStatus == 'smallChild') {
          const token = this.helpers.clone(localStorage.getItem('mean-token'));
          console.log(
            '🚀 ~ file: profile.component.ts:40 ~ ProfileComponent ~ .subscribe ~ token:',
            token
          );
          if (token) {
            // localStorage.removeItem('mean-token');
            if (
              confirm('Wykryto konto dziecka. Przejść do aplikacji dla dzieci?')
            ) {
              window.location.href =
                'https://int2.linget.it/login?token=' + token;
            } else {
              this.auth.logout();
            }
          }
        }

      });

    this.userDetailsService.getUserDetails();

    // setTimeout(() => {
    //   const lastResetProgressDate =
    //     this.userDetailsService.userDetails.userData.lastResetProgress;

    //   const currentDate = Date.now();
    //   const numberMilisecondsFromLastReset =
    //     currentDate - lastResetProgressDate;
    //   console.log(
    //     '🚀 ~ file: profile.component.ts:31 ~ ProfileComponent ~ setTimeout ~ numberMilisecondsFromLastReset:',
    //     numberMilisecondsFromLastReset
    //   );

    //   if (
    //     numberMilisecondsFromLastReset > 604800000 ||
    //     !lastResetProgressDate
    //   ) {
    //     this.resetProgress();
    //   }
    // }, 1000);
  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }
  resetProgress() {
    this.userDetailsService.resetProgress();
  }
}
