import { Component, Inject, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { PrizesService } from 'src/app/shared/services/prizes.service';

@Component({
  selector: 'app-choose-prize',
  templateUrl: './choose-prize.component.html',
  styleUrls: ['./choose-prize.component.css'],
})
export class ChoosePrizeComponent implements OnInit {
  prizes = [];
  travelCards = [
    {
      img: '',
      id: 62,
      name: 'The Perito Moreno Glacier - Santa Cruz - Argentina',
      description:
        'The Perito Moreno Glacier is a glacier located in the Los Glaciares National Park in the Santa Cruz province, Argentina. The glacier is about 30 kilometers long, and between 2 and 4 kilometers wide. It is always moving, and when it gets too close to the river, it can break off and create a huge wave.',
    },

    {
      img: '',
      id: 81,
      name: 'Tasmania - Australia',
      description:
        'Tasmania is a small island off the coast of Australia. It is known for its natural beauty, including its many national parks. It is a mountainous island and is known for its beautiful forests and wildlife. The Tasmanian Devil is a small animal that is found only in Tasmania.',
    },

    {
      img: '',
      id: 91,
      name: 'Hallstatt - Austria',
      description:
        'Hallstatt is a small town in Austria that is famous for its beautiful lakes and mountains. It is located in the mountains, and it is surrounded by lakes and forests. It is also known for its ancient salt mine, which has been in operation for over 2,000 years and is one of the oldest in the world!',
    },

    {
      img: '',
      id: 111,
      name: 'The Bahamas - The Caribbean Sea',
      description:
        'The Bahamas is a country made up of many different islands. Some of the most famous islands are Nassau and Paradise Island. The people who live in the Bahamas are called Bahamians. They are friendly and welcoming people. The Bahamas is known for its beautiful beaches, clear water, and tropical climate.',
    },

    {
      img: '',
      id: 201,
      name: 'Laguna Verde - Bolivia',
      description:
        'Laguna Verde is a beautiful, bright green lake located in the south of Bolivia, near the border with Chile. It is surrounded by volcanoes, including the famous one, Licancabur. The volcano is constantly smoking and spewing hot ash and rocks into the air.',
    },

    {
      img: '',
      id: 231,
      name: 'The Amazon Rainforest - Brazil',
      description:
        "The Amazon rainforest is located in South America, and covers parts of Brazil, Peru, Ecuador, Colombia, and Venezuela. It's the largest rainforest in the world. It is home to a variety of animals, including jaguars, monkeys, and sloths. More than two-thirds of all known species of plants and animals live in the Amazon.",
    },

    {
      img: '',
      id: 311,
      name: 'Emerald Lake - British Columbia - Canada',
      description:
        "Emerald Lake is located in Yoho National Park in Canada, near the border of British Columbia and Alberta. The lake is surrounded by towering mountains and lush forests, and it's home to many different species of wildlife. Visitors can enjoy hiking, camping, fishing, and other outdoor activities in the area.",
    },

    {
      img: '',
      id: 341,
      name: 'Rano Raraku - Easter Island - Chile',
      description:
        'Rano Raraku is an extinct volcano on Easter Island. It is famous for the moai statues that are carved into its side. The moai statues are up to 40 feet tall and weigh over 80 tons! People believe that the statues were carved by the Rapa Nui people, who lived on Easter Island from around the 1300s to the 1800s.',
    },

    {
      img: '',
      id: 352,
      name: 'Zhangye National Geopark - China',
      description:
        'The Zhangye National Geopark is located in the Gansu Province of China. The park is known for its colorful and dramatic rock formations, including the world-famous Rainbow Mountain. The park is also home to a variety of plant and animal life, including the endangered giant panda.',
    },

    {
      img: '',
      id: 451,
      name: 'The Faroe Islands - Denmark',
      description:
        'The Faroe Islands are a group of islands in the North Atlantic Ocean. They are part of Denmark, but they have their own government and language. They are famous for their beautiful scenery. They are home to about 50,000 people and 70,000 sheep. It’s such a safe place that there aren’t any prisons.',
    },

    {
      img: '',
      id: 511,
      name: 'Queen Hatshepsut Temple - Egypt',
      description:
        "Hatshepsut Temple is located in the city of Deir el-Bahri, which is near the capital city of Cairo. It is dedicated to the god Amun. It is one of the most famous temples in Egypt. It was built by Queen Hatshepsut, who was one of the most successful pharaohs in Egypt's history.",
    },

    {
      img: '',
      id: 601,
      name: 'Gorges du Verdon - France',
      description:
        'The Gorges du Verdon is a natural wonder in southeastern France. It is about 25 kilometers long and up to 700 meters deep. The Verdon River flows through the gorge, creating spectacular waterfalls and cliffs. The Gorges du Verdon is a popular tourist destination and a great place for hiking, camping, and fishing.',
    },

    {
      img: '',
      id: 781,
      name: 'Godafoss - Iceland',
      description:
        'Goðafoss is a waterfall in northern Iceland. The waterfall got its name from a man who threw his statues of the Norse gods into the waterfall to show his people that the old gods were no longer worshipped. Its name means waterfall of the gods.',
    },

    {
      img: '',
      id: 841,
      name: 'Dead Sea - Israel',
      description:
        "The Dead Sea is a salt lake that is almost 1,000 feet below sea level. It is the lowest point on Earth! It is located in the Middle East, between Israel and Jordan. It is a place where people go to relax and enjoy the clear water. The Dead Sea is so salty that you can't sink in it and no plants or animals can live in it.",
    },

    {
      img: '',
      id: 851,
      name: 'Burano Island - Italy',
      description:
        "Burano is a small island in Italy located north of Venice. It is famous for its brightly colored fishermen's houses. Visitors can enjoy the island's charming streets, its delicious food, and its lovely views. The people of Burano are very friendly and welcoming. There are many restaurants and shops on the island.",
    },

    {
      img: '',
      id: 881,
      name: 'Bamboo Forest - Kyoto - Japan',
      description:
        "Bamboo Forest is a famous tourist spot in Kyoto, Japan. It's a beautiful forest made up of bamboo trees. The trees are so tall that you can't see the sky! The bamboo makes a soft noise when the wind blows through it. The forest is a great place to visit in the spring and fall, when the leaves are changing colors.",
    },

    {
      img: '',
      id: 1141,
      name: 'Ik Kil Cenote - Mexico',
      description:
        "Ik Kil Cenote is a natural wonder in Mexico. It's a beautiful, clear pool of water that's perfect for swimming. There's a small waterfall that you can swim under, and the cave around the pool is a great place to explore. It was used by the ancient Maya for religious ceremonies and as a source of fresh water.",
    },

    {
      img: '',
      id: 1271,
      name: 'Kinderdijk - the Netherlands',
      description:
        'Kinderdijk is a place in the Netherlands that is famous for its windmills. There are nineteen windmills in Kinderdijk, and they are all over two hundred years old! The windmills are used to help pump water out of the area, and they are a popular tourist destination.',
    },

    {
      img: '',
      id: 1281,
      name: 'Waiotapu - New Zealand',
      description:
        'Waiotapu is a beautiful and unique place. It is a volcanic lake located in the Taupo Volcanic Zone in the North Island of New Zealand. The lake is famous for its vivid colors, ranging from blues and greens to yellows and oranges, caused by the minerals in the water.',
    },

    {
      img: '',
      id: 1341,
      name: 'Norway - Lovatnet',
      description:
        "Lovatnet is a beautiful lake in the heart of the mountains of Norway near the town of Fagernes. The lake is surrounded by forests and mountains. It's a great place for fishing, swimming, and hiking. The views from the lake are stunning, and it's a popular spot for camping.",
    },

    {
      img: '',
      id: 1413,
      name: 'The Andes - Peru',
      description:
        'The Andes are the longest mountain range in the world. They run through Peru, Chile, Bolivia, and Argentina. The highest peak in the Andes is Mount Aconcagua, which is 6,962 meters high. They are home to many animals, including llamas and alpacas, as well as plants, such as coffee beans and coca leaves.',
    },

    {
      img: '',
      id: 1421,
      name: 'Bangaan Rice Terraces - the Philippines',
      description:
        'The Bangaan Rice Terraces are located in the Philippines and are made up of carved rice paddies that are built into the mountainside. The terraces are over 2,000 years old and are still in use today. They are a popular tourist attraction and are a great place to see the traditional way of life in the Philippines.',
    },

    {
      img: '',
      id: 1441,
      name: 'Algarve - Portugal',
      description:
        "Algarve is a region in southern Portugal. It's known for its beautiful coastline with rocky cliffs and sandy beaches, sunny weather, and delicious food. The Algarve is a great place to visit if you're looking for a relaxing vacation by the beach.",
    },

    {
      img: '',
      id: 1501,
      name: 'Saint Lucia',
      description:
        "Saint Lucia is a small island country in the eastern Caribbean Sea. It's known for its white sand beaches and crystal water. Visitors can enjoy plenty of activities such as snorkeling, swimming, and sailing. There are also plenty of restaurants and bars where you can enjoy the local cuisine and drinks.",
    },

    {
      id: 1571,
      name: 'Seychelles',
      description:
        'The Seychelles are a group of 115 islands in the Indian Ocean east of Kenya and north of Madagascar. The country is known for its beaches, coral reefs, and animals such as lemurs, giant tortoises, and dolphins. The people of the Seychelles are very friendly. They speak English, French, and local languages.',
    },

    {
      img: '',
      id: 1611,
      name: 'Lake Bled - Julian Alps - Slovenia',
      description:
        "Lake Bled is a beautiful lake in the Julian Alps. It's famous for its clear blue water and the island in the middle of the lake. You can visit the island by boat. There is a church on the island, and you can ring the bell to make a wish. Hike up to the top of Mount Ojstrica to enjoy an amazing view of the lake.",
    },

    {
      img: '',
      id: 1641,
      name: 'Cape Town - South Africa',
      description:
        'Cape Town is a city in South Africa. It is located on the Cape of Good Hope. Cape Town is a popular tourist destination. It is near the ocean and has a lot of mountains. There are a lot of different cultures in Cape Town, and it is a great place to visit. The people are friendly, and the food is delicious.',
    },

    {
      img: '',
      id: 1671,
      name: 'Gothic Quarter - Barcelona - Spain',
      description:
        "The Gothic Quarter in Barcelona is one of the oldest areas in the city. It's full of old buildings and churches, and it's right in the city center. There are lots of narrow streets and alleyways to explore. You can find all sorts of shops and restaurants there, and there's always something going on.",
    },

    {
      img: '',
      id: 1674,
      name: 'Las Teresitas Beach - Tenerife - Spain',
      description:
        "Las Teresitas Beach is a beautiful, sandy beach located in the north of Tenerife. It's well known for its stunning views of the Atlantic Ocean and its crystal waters. The beach is a great place to relax, sunbathe, and swim, and there are also plenty of restaurants and bars nearby if you want to grab a bite or a drink.",
    },

    {
      img: '',
      id: 1751,
      name: 'The Matterhorn - Italian/Swiss Alps',
      description:
        'Mount Matterhorn is located in the Alps mountain range between Switzerland and Italy. It is about 4,478 meters high. The Matterhorn was first climbed in 1865 by a group of four Englishmen. It is one of the most famous and recognizable mountains in the world.',
    },

    {
      img: '',
      id: 1791,
      name: 'The Red Temple - Thailand',
      description:
        'The Red Temple is located in Thailand and is one of the most popular tourist destinations in the country. The temple is known for its bright red color, which is said to ward off evil spirits. The temple is also home to a number of Buddha statues and other religious artifacts.',
    },

    {
      img: '',
      id: 1911,
      name: 'The Dark Hedges - Northern Ireland',
      description:
        'The Dark Hedges in Northern Ireland are 150-year-old beech trees that were planted in two rows and grow so close together that they create an arch overhead. The Dark Hedges are now one of the most photographed places in Northern Ireland and have been featured in the Game of Thrones TV series.',
    },

    {
      img: '',
      id: 1931,
      name: 'Monument Valley - the USA',
      description:
        'Monument Valley is located in the states of Arizona and Utah in the USA. It is a beautiful place with red sandstone rocks, which are the result of millions of years of erosion. The valley is home to the Native American nation of Navajo.',
    },

    {
      img: '',
      id: 1934,
      name: 'Multnomah Falls - Oregon - the USA',
      description:
        "Multnomah Falls is a waterfall on the Columbia River Gorge in Oregon. It's one of the most popular tourist destinations in the state. Multnomah Falls is 189 m, making it the second tallest waterfall in the United States. It is named after the Multnomah tribe of Native Americans who lived in the area.",
    },

    {
      img: '',
      id: 2011,
      name: 'Ha Long Bay - Vietnam',
      description:
        "Ha Long Bay is a place in Vietnam where there are a lot of tall, rocky islands. The water is really clear and blue, so you can see all kinds of fish and other animals swimming around. It's a beautiful place to visit. There are a lot of boats that go there, and you can go swimming or kayaking.",
    },

    {
      img: '',
      id: 1935,
      name: 'Death Valley - California - the USA',
      description:
        'Death Valley is a beautiful and mysterious place located in California. It is known for its extreme heat and barren landscape. The valley was named for the many people who died there while crossing it on their way to California. The valley is named for the death of a group of pioneers who got lost there in 1849.',
    },

    {
      img: '',
      id: 1361,
      name: 'Attabad Lake - Pakistan',
      description:
        "Attabad Lake is a beautiful place to visit. It's located in the Hunza Valley in Pakistan. The lake was formed in 2010 when a landslide blocked the river. The lake is over 18 miles long and covers an area of over 12 square miles. It's a great place to go fishing, hiking, and camping.",
    },

    {
      img: '',
      id: 401,
      name: 'Plitvice Lakes National Park - Croatia',
      description:
        'The Plitvice Lakes are a group of sixteen lakes in Croatia. They are all beautiful and different, and they are all connected by waterfalls. The lakes are in a national park, and people come from all over the world to see them. The National Park was founded in 1949 and is visited by almost 1.3 million people annually.',
    },

    {
      img: '',
      id: 671,
      name: 'Meteora - Greece',
      description:
        'Meteora is a magical place in Greece. It is a group of six monasteries built on huge rocks in central Greece. The monasteries are built on top of tall rocks, and visitors can hike up to them or go up in a cable car to see the stunning architecture and views.',
    },

    {
      img: '',
      id: 1913,
      name: 'Trotternish - the Isle of Skye - Scotland',
      description:
        'Trotternish is a peninsula on the Isle of Skye. It is known for its dramatic cliffs and stunning coastline. There are beautiful mountains and valleys to explore, and you can also visit some of the local villages. The people there are friendly and welcoming, and you can enjoy the delicious food and traditional music.',
    },
  ];

  selectedBox = null;
  boxes = [
    {
      id: 1,
      boxPicture: '/assets/boxes/box_b.svg',
      topPicture: '/assets/boxes/top_b.svg',
      itemInTheBox: {
        img: '/assets/collectibles/3.svg',
      },
      showTop: true,
      makeTada: false,
      opened: false,
      showCard: false,
    },
    {
      id: 2,
      boxPicture: '/assets/boxes/box_p_1.svg',
      topPicture: '/assets/boxes/top_p.svg',
      itemInTheBox: {
        img: '/assets/collectibles/2.svg',
      },
      showTop: true,
      makeTada: false,
      opened: false,
      showCard: false,
    },
    {
      id: 3,
      boxPicture: '/assets/boxes/box_v.svg',
      topPicture: '/assets/boxes/top_v.svg',
      itemInTheBox: {
        img: '/assets/collectibles/1.svg',
      },
      showTop: true,
      makeTada: false,
      opened: false,
      showCard: false,
    },
  ];
  picturesSub: any;
  chosenPrizes = [];
  data;
  //put data here
  constructor(
    private helpers: HelpersService,
    public userDetailsService: UserDetailsService,
    private prizesService: PrizesService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit(): void {
    setTimeout(() => {
      this.prizes = this.prizesService.prizes;
      this.prizes.forEach((prize) => {
        const prizeNumberedLevel = this.prizesService.checkCollectablesLevel(
          prize.id
        ).numberedLevel;
        for (let index = 0; index < 3 - prizeNumberedLevel; index++) {
          this.chosenPrizes.push(prize);
        }
      });
      const rarePrizes = this.chosenPrizes.filter(
        (prize) => prize.rarity == 'Rare'
      );
      const commonPrizes = this.chosenPrizes.filter(
        (prize) => prize.rarity == 'Common'
      );
      const commonPrizes2 = this.chosenPrizes.filter(
        (prize) => prize.rarity == 'Common'
      );
      this.chosenPrizes = this.chosenPrizes.concat(rarePrizes);
      this.chosenPrizes = this.chosenPrizes.concat(commonPrizes2);
      this.chosenPrizes = this.chosenPrizes.concat(commonPrizes);
      console.log(
        '🚀 ~ file: choose-prize.component.ts ~ line 414 ~ ChoosePrizeComponent ~ setTimeout ~ this.chosenPrizes',
        this.chosenPrizes
      );
      this.boxes.forEach((box) => {
        box.itemInTheBox =
          this.chosenPrizes[
            this.helpers.getRandomNumber(0, this.chosenPrizes.length - 1)
          ];
      });
    }, 1000);
  }
  checkOpenedBoxes() {
    return this.boxes.some((box) => box.opened);
  }
  openBox(box) {
    setTimeout(() => {
      box.opened = true;
      setTimeout(() => {
        box.makeTada = true;
        setTimeout(() => {
          box.showCard = true;
          box.makeTada = false;
        }, 1500);
      });
    }, 1000);
    box.openTop = true;
    setTimeout(() => {
      box.showTop = false;
      box.openTop = false;
      this.selectedBox = box;
      box.makeTada = false;
    }, 1500);
  }
  getPrize(item) {
    let previousLevel = this.prizesService.checkCollectablesLevel(item.id);
    if (previousLevel.numberedLevel == 0) {
      item.numberedLevel = 1;
      item.level = ['*', '', ''];
    } else if (previousLevel.numberedLevel == 1) {
      item.numberedLevel = 2;
      item.level = ['*', '*', ''];
    } else if (previousLevel.numberedLevel == 2) {
      item.numberedLevel = 3;
      item.level = ['*', '*', '*'];
    }
    item.fromSet = this.data?.setId;
    this.userDetailsService.addusersCollectableItems(
      this.userDetailsService.getSimpleUserDetails().id,
      item
    );
    let userDetails = this.userDetailsService.getUserDetails();
    if (!userDetails.userData.collectables) {
      userDetails.userData.collectables = [];
    }
    userDetails.userData.collectables.push(item);
    this.userDetailsService.storeUserDetails(userDetails);
    this.modalCtrl.dismiss();
  }
  // showCollectables() {
  //   const dialogRef = this.dialog.open(CollectablesViewComponent, {
  //     minHeight: "100vh",
  //     height: "100vh",
  //     minWidth: "100vw",
  //     width: "100vw",
  //     panelClass: "full-screen-dialog-no-wrap",
  //     data: this.data.userId.userData.collectables,
  //   });
  // }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
  }
}
