import { Observable, BehaviorSubject, interval } from 'rxjs';
import { map, shareReplay, takeWhile } from 'rxjs/operators';

export class TimerService {
  private stopwatch$ = new BehaviorSubject<number>(0);
  private running: boolean = false;

  get timer$(): Observable<string> {
    return this.stopwatch$.pipe(
      map((ticks) => {
        const minutes = Math.floor(ticks / 6000);
        const seconds = Math.floor((ticks % 6000) / 100);
        const milliseconds = ticks % 100;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}:${
          milliseconds < 10 ? '0' : ''
        }${milliseconds}`;
      }),
      shareReplay()
    );
  }

  start() {
    if (!this.running) {
      interval(10)
        .pipe(takeWhile(() => this.running))
        .subscribe(() => {
          this.stopwatch$.next(this.stopwatch$.value + 1);
        });
      this.running = true;
    }
  }

  stop() {
    this.running = false;
  }

  reset() {
    this.stopwatch$.next(0);
  }
}
