import { Component, OnInit } from '@angular/core';
import {
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { PlatformService } from '../../services/platform.service';
import { MenuController } from '@ionic/angular';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-toolbar-w-menu',
  templateUrl: './toolbar-w-menu.component.html',
  styleUrls: ['./toolbar-w-menu.component.scss'],
})
export class ToolbarWMenuComponent implements OnInit {
  name = 'Krzysztof';
  showBackButton = true;
  userDetails: Observable<any>;

  constructor(
    public userDetailsService: UserDetailsService,
    private platform: PlatformService,
    private router: Router,
    private menu: MenuController
  ) {}

  ngOnInit() {
    this.ionViewWillEnter();
  }

  ionViewWillEnter() {
    this.userDetails = this.userDetailsService.userDetailsBearer.asObservable();
    this.userDetailsService.getUserDetails();

    this.menu.enable(true);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
      }

      if (event instanceof NavigationEnd) {
        if (event.url.includes('profile')) {
          this.showBackButton = false;
        } else if (!this.router.url.includes('profile')) {
          this.showBackButton = true;
        }
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }

  openMenu() {
    this.menu.enable(true);
    this.menu.open('first');
  }
}
