import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HelpersService } from '../../services/helpers.service';

@Component({
  selector: 'app-student-dictionary-dialog',
  templateUrl: './student-dictionary-dialog.component.html',
  styleUrls: ['./student-dictionary-dialog.component.scss'],
})
export class StudentDictionaryDialogComponent implements OnInit {
  foundWords;
  sortedWords = [];
  index: number;
  keyboardHeight: number;

  constructor(
    private modalCtrl: ModalController,
    private helpers: HelpersService
  ) {}

  ngOnInit() {
    this.sortMeanings();
  }

  sortMeanings() {
    this.foundWords = this.helpers.sortArrayByProperty(
      this.foundWords,
      'cefr',
      1
    );
    this.foundWords.forEach((word: any) => {
      switch (word.cefr) {
        case 'a1':
          word.color = 'orange';
          break;
        case 'a2':
          word.color = 'red';
          break;
        case 'b1':
          word.color = 'green';
          break;
        case 'b2':
          word.color = 'blue';
          break;
        case 'c1':
          word.color = 'purple';
          break;
        case 'c2':
          word.color = 'black';
          break;

        default:
          break;
      }
    });
    this.index = 0;
    if (this.foundWords?.length > 0) {
      this.sortedWords = [
        this.foundWords.filter((word: any) => word.cefr === 'a1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'a2').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'b1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'b2').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'c1').reverse(),
        this.foundWords.filter((word: any) => word.cefr === 'c2').reverse(),
      ];
      console.log('-> sortedWords', this.sortedWords);
    }
  }

  dismiss() {
    return this.modalCtrl.dismiss(null, 'hide');
  }
}
