import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collectable-planet',
  templateUrl: './collectable-planet.component.html',
  styleUrls: ['./collectable-planet.component.css'],
})
export class CollectablePlanetComponent implements OnInit {
  @Input() planet;

  constructor() {}

  ngOnInit(): void {}
}
