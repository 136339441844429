import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent {
  rows = [
    [
      { label: 'Q', disabled: false },
      { label: 'W', disabled: false },
      { label: 'E', disabled: false },
      { label: 'R', disabled: false },
      { label: 'T', disabled: false },
      { label: 'Y', disabled: false },
      { label: 'U', disabled: false },
      { label: 'I', disabled: false },
      { label: 'O', disabled: false },
      { label: 'P', disabled: false },
    ],
    [
      { label: 'A', disabled: false },
      { label: 'S', disabled: false },
      { label: 'D', disabled: false },
      { label: 'F', disabled: false },
      { label: 'G', disabled: false },
      { label: 'H', disabled: false },
      { label: 'J', disabled: false },
      { label: 'K', disabled: false },
      { label: 'L', disabled: false },
    ],
    [
      { label: 'Z', disabled: false },
      { label: 'X', disabled: false },
      { label: 'C', disabled: false },
      { label: 'V', disabled: false },
      { label: 'B', disabled: false },
      { label: 'N', disabled: false },
      { label: 'M', disabled: false },
      { label: '⌫', disabled: false },
    ],
  ];

  @Output() keyClick = new EventEmitter<string>();
  @Output() backspaceClick = new EventEmitter<void>();

  onKeyClick(key: { label: string; disabled: boolean }): void {
    if (!key.disabled) {
      this.keyClick.emit(key.label);
    }
  }

  onBackspaceClick(): void {
    this.backspaceClick.emit();
  }
}
