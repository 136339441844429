import { Directive, ElementRef, OnInit } from '@angular/core';
import * as Hammer from 'hammerjs';

@Directive({
  selector: '[appDisablePullToRefresh]',
})
export class DisablePullToRefreshDirective implements OnInit {
  private hammer: InstanceType<typeof Hammer.Manager>;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.hammer = new Hammer.Manager(this.elementRef.nativeElement, {
      touchAction: 'pan-y',
    });

    this.hammer.add(
      new Hammer.Pan({ direction: Hammer.DIRECTION_VERTICAL, threshold: 0 })
    );

    this.hammer.on('panstart', (event) => {
      if (
        event.direction === Hammer.DIRECTION_DOWN &&
        window.pageYOffset === 0
      ) {
        event.preventDefault();
      }
    });
  }
}
