import { Component, Inject, OnInit } from '@angular/core';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { PrizesService } from 'src/app/shared/services/prizes.service';

@Component({
  selector: 'app-collectables-view',
  templateUrl: './collectables-view.component.html',
  styleUrls: ['./collectables-view.component.css'],
})
export class CollectablesViewComponent implements OnInit {
  constructor(
    public userDetailsService: UserDetailsService,
    private prizesService: PrizesService
  ) {}
  data;
  //pass data here
  simpleCollectables = [];
  collectables = [];
  planets = [];
  allChildrenCollectables = [];
  ngOnInit(): void {
    this.allChildrenCollectables = this.prizesService.prizes;

    this.simpleCollectables = this.data;
    if (
      this.userDetailsService.checkAgeStatus() === 'smallChild' ||
      this.userDetailsService.checkAgeStatus() === 'mediumChild'
    ) {
      this.simpleCollectables = this.simpleCollectables?.filter(
        (collectable) => !collectable.img.includes('lingking.com.pl')
      );
      this.simpleCollectables?.forEach((collectable) => {
        let foundCollectables = this.allChildrenCollectables.filter(
          (c) => c.id === collectable.id
        );
        let level = [];
        for (let index = 0; index < 3; index++) {
          if (index < foundCollectables.length) {
            level.push('*');
          } else {
            level.push('');
          }
        }
        foundCollectables[0].level = level;
        this.collectables.push(foundCollectables[0]);
      });
      console.log(
        '🚀 ~ file: collectables-view.component.ts ~ line 34 ~ CollectablesViewComponent ~ this.simpleCollectables.forEach ~ this.collectables',
        this.collectables
      );
      this.planets = this.prizesService.getCategorizedPrizes();
    }
  }
}
