import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sets-progress-diamonds',
  templateUrl: './sets-progress-diamonds.component.html',
  styleUrls: ['./sets-progress-diamonds.component.scss'],
})
export class SetsProgressDiamondsComponent implements OnInit {
  @Input() items;
  @Input() knownWords;
  @Input() divideCount;
  @Input() numberOfRuns;
  progress = [];
  numberOfStars;
  numberOfQuestionsInRound;

  constructor() {}

  ngOnInit(): void {
    this.knownWords++;

    if (this.items.length < 30) {
      this.numberOfStars = Math.round(this.items.length / 3);
      for (let i = 0; i < 3; i++) {
        this.progress.push(false);
      }
      for (let index = 0; index < this.divideCount; index++) {
        this.progress.splice(this.progress.indexOf(false), 1);
        this.progress.unshift(true);
      }
    } else {
      this.numberOfStars = Math.round(this.items.length / 6);
      for (let i = 0; i < 6; i++) {
        this.progress.push(false);
      }
      for (let index = 0; index < this.divideCount; index++) {
        this.progress.splice(this.progress.indexOf(false), 1);
        this.progress.unshift(true);
      }
    }

    if (this.numberOfRuns > 0) {
      this.progress = this.progress.map((e) => true);
    }
  }
}
