import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActionSheetController, ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { HelpersService } from 'src/app/shared/services/helpers.service';
import { PlatformService } from 'src/app/shared/services/platform.service';
import { AnswerSummaryModalComponent } from './questions-quiz-elements/answer-summary-modal/answer-summary-modal.component';
import { SpeechToTextService } from 'src/app/shared/services/speech-recognition.service';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  currentQuestion;
  flashcardsMode = false;
  url;
  lang: 'en' | 'es' = 'en';
  quizFinished = false;
  divideCount = 0;
  quizItems = [];
  itemNumber = 0;
  multiModeNumber = 0;
  startThinking = 0;
  _nextQuestion = new Subject();
  _nextFlashcard = new Subject();
  flipSpeaking = new Subject()
  constructor(
    private actionSheetCtrl: ActionSheetController,
    private helpers: HelpersService,
    private userDetailsService: UserDetailsService,
    private http: HttpClient,
    private platformService: PlatformService,
    private modalCtrl: ModalController
  ) // private speechToTextService: SpeechToTextService
  {
    this.url = this.platformService.url;
  }
  nextFlashcard() {
    this.itemNumber++;
    this.currentQuestion = this.quizItems[this.itemNumber];
    this._nextFlashcard.next(this.currentQuestion);
  }
  public checkAnswer(
    answer: 'correct' | 'wrong',
    ratio?,
    autoDismiss?,
    recognizedText?,
    selectedWrongAnswer?,
    correctAnswer?
  ) {
    if (!this.flashcardsMode) {
      if (this.currentQuestion?.sentenceAudioES?.length > 0) {
        this.lang = 'es';
      } else {
        this.lang = 'en';
      }
      this.presentActionSheet(
        answer,
        ratio,
        autoDismiss,
        recognizedText,
        selectedWrongAnswer,
        correctAnswer
      );
      this.bareUpdateStats();
      console.log(
        '🚀 ~ file: quiz.service.ts:48 ~ QuizService ~ checkAnswer ~ this.itemNumber:',
        this.itemNumber
      );
    }
  }
  flipSpeakingAndSentence() {
    this.flipSpeaking.next('flip')
  }
  presentActionSheet(
    answer: 'correct' | 'wrong',
    ratio?,
    autoDismiss?,
    recognizedText?,
    selectedWrongAnswer?,
    correctAnswer?
  ) {
    this.openAnswerSummaryModal(
      answer,
      ratio,
      autoDismiss,
      recognizedText,
      selectedWrongAnswer,
      correctAnswer
    );
  }

  async openAnswerSummaryModal(
    answer: 'correct' | 'wrong',
    ratio?,
    autoDismiss?,
    recognizedText?,
    selectedWrongAnswer?,
    correctAnswer?
  ) {
    const dataToSend = {
      question: this.currentQuestion,
      answer: answer,
      ratio: ratio,
      autoDismiss: autoDismiss,
      fromSpeaking: ratio > 0,
      recognizedText: recognizedText,
      selectedWrongAnswer: selectedWrongAnswer,
    };

    const modal = await this.modalCtrl.create({
      component: AnswerSummaryModalComponent,
      cssClass: 'bottom-modal',
      backdropDismiss: false,

      componentProps: {
        data: dataToSend,
      },
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === 'again') {
      console.log('again');
    }
    if (role === 'next') {
      console.log('next');
      this.nextQuestion(answer, correctAnswer);
    }
  }

  updateStats(
    userDetails,
    studentId,
    setId,
    weeklyProgress,
    finished,
    currentSet,
    quizParams
  ) {
    const statsId = this.helpers.makeid(8);
    const setToUpdate = this.userDetailsService.currentSet;
    if (setToUpdate) {
      if (!setToUpdate.stats && currentSet) {
        setToUpdate.stats = {
          lastLearned: 0,
          lastQuestionTime: 0,
          numberOfRuns: 0,
          startTime: Date.now(),
          totalItems: 0,
          weeklyProgress: 0,
          quizParams,
        };
        setToUpdate.stats.weeklyProgress = weeklyProgress ? weeklyProgress : 0;
      } else if (setToUpdate.stats) {
        console.log(
          '🚀 ~ file: quiz.service.ts:113 ~ QuizService ~ setToUpdate.stats:',
          setToUpdate.stats
        );
        setToUpdate.stats.quizParams = quizParams;
        setToUpdate.stats.weeklyProgress = weeklyProgress;
        setToUpdate.stats.lastQuestionTime = Date.now();
        setToUpdate.stats.quizParams.multiQuizParams =
          quizParams.multiQuizParams;
        if (finished) {
          setToUpdate.stats.items = [];
          setToUpdate.stats.knownItems = [];
          if (setToUpdate.stats.quizParams?.multiQuizParams) {
            setToUpdate.stats.quizParams.multiQuizParams.multiModeNumber = 0;
            setToUpdate.stats.quizParams.multiQuizParams.divideCount = 0;
            setToUpdate.stats.quizParams.multiQuizParams.itemNumber = 0;
            setToUpdate?.stats?.numberOfRuns
              ? setToUpdate.stats.numberOfRuns++
              : (setToUpdate.stats.numberOfRuns = 1);
          }
          setToUpdate.stats.weeklyProgress = weeklyProgress;
          setToUpdate.stats.lastQuestionTime = Date.now();
        }
      }
      let _weeklyProgress = 0;
      userDetails.userData.sets.forEach((set) => {
        if (set?.stats?.weeklyProgress > 0) {
          _weeklyProgress += set.stats?.weeklyProgress;
        }
      });
      userDetails.userData.weeklyProgress = _weeklyProgress;
      currentSet.stats = setToUpdate.stats;
      this.userDetailsService.storeUserDetails(userDetails);
      this.userDetailsService.storeCurrentSet(setToUpdate);
      this.userDetailsService.storeCurrentSetsStats(setToUpdate.stats);
    }
    quizParams.lastLearned = Date.now();
    this.http
      .put(this.url + '/api/customers/update-stats/' + studentId, {
        stats: 'no',
        setId: setId,
        statsItemUpdate: {},
        statsId: statsId,

        knownItemsUpdate: {},
        weeklyProgress: weeklyProgress,
        finished: finished,
        quizParams: quizParams,
      })
      .subscribe((response) => {
        console.log(
          '🚀 ~ file: quiz.service.ts:168 ~ QuizService ~ .subscribe ~ response:',
          response
        );
      });
  }
  bareUpdateStats(finished?) {
    const thinkingTime = (Date.now() - this.startThinking) / 1000;
    console.log(
      '🚀 ~ file: quiz.service.ts:156 ~ QuizService ~ bareUpdateStats ~ thinkingTime:',
      thinkingTime
    );
    const stats = this.userDetailsService.getCurrentSetsStats();
    const previousWeeklyProgress = stats?.weeklyProgress;
    let weeklyProgressToSend = previousWeeklyProgress;
    console.log(
      '🚀 ~ file: quiz.service.ts:179 ~ QuizService ~ bareUpdateStats ~ thinkingTime:',
      thinkingTime
    );
    if (thinkingTime < 120) {
      weeklyProgressToSend = thinkingTime + previousWeeklyProgress;
    }

    this.updateStats(
      this.userDetailsService.getUserDetails(),
      this.userDetailsService.userDetails._id,
      this.userDetailsService.currentSet.id,
      weeklyProgressToSend,

      finished,
      this.userDetailsService.currentSet,
      {
        multiQuizParams: {
          divideCount: this.divideCount,
          itemNumber: this.itemNumber + 1,
          multiModeNumber: this.multiModeNumber,
          // push real params here
          multiParams: [
            {
              readFull: true,
              multi: true,
              divide: true,
              write: false,
              onlyWrite: false,
              makeSentence: false,
              gif: false,
              translation: false,
              buttonClues: true,
              answerMode: 'sentence',
              writeClue: true,
            },
            {
              readFull: true,
              multi: true,
              divide: true,
              write: false,
              onlyWrite: true,
              answerMode: 'sentence',
              makeSentence: false,
              gif: false,
              translation: false,
              buttonClues: true,
              writeClue: true,
              speakSentence: false,
            },
            {
              readFull: false,
              multi: true,
              divide: true,
              write: false,
              onlyWrite: true,
              makeSentence: false,
              gif: true,
              answerMode: 'sentence',
              translation: false,
              buttonClues: true,
              writeClue: true,
            },
          ],
        },
      }
    );
  }
  async showActionSheet(answer) {
    const selection = await this.presentActionSheet(answer);
  }
  nextQuestion(answer, correctAnswer?) {
    // this.speechToTextService.speakingTry = 0;
    this._nextQuestion.next({ answer: answer, correctAnswer: correctAnswer });
  }
  nextQuestionUpdatedListener() {
    return this._nextQuestion.asObservable();
  }
  nextFlashcardUpdatedListener() {
    return this._nextFlashcard.asObservable();
  }
  flipSpeakingUpdatedListener() {
    return this.flipSpeaking.asObservable();
  }
}
