import { Component, OnInit } from '@angular/core';
import { PrizesService } from 'src/app/shared/services/prizes.service';

@Component({
  selector: 'app-collectable-planets-wrapper',
  templateUrl: './collectable-planets-wrapper.component.html',
  styleUrls: ['./collectable-planets-wrapper.component.css'],
})
export class CollectablePlanetswrapperComponent implements OnInit {
  planets = [];
  currentPlanetNumber = 0;
  showPlanet = true;
  constructor(private prizesService: PrizesService) {}

  ngOnInit(): void {
    this.planets = this.prizesService.getCategorizedPrizes();
  }
  changePlanet(mode) {
    if (mode === 'next') {
      if (this.currentPlanetNumber !== 11) {
        this.currentPlanetNumber++;
      } else {
        this.currentPlanetNumber = 0;
      }
    }
    if (mode === 'back') {
      if (this.currentPlanetNumber !== 0) {
        this.currentPlanetNumber--;
      } else {
        this.currentPlanetNumber = 11;
      }
    }
  }
}
