import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, from, switchMap } from 'rxjs';
import { HelpersService } from './helpers.service';
import * as toWav from 'audiobuffer-to-wav';
import { PlatformService } from './platform.service';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { QuizService } from 'src/app/home/learn/quiz-wrapper/quizzes/multi-quiz/questions-quiz/quiz.service';

interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

@Injectable({
  providedIn: 'root',
})
export class SpeechToTextService {
  private url;
  private headers = new HttpHeaders({ 'Content-Type': 'audio/wav' });
  recognitionData = new Subject();
  recognitionPending = false;
  browserRecognitionSupported: any;
  ratioToCorrectAnswer: number = 0;
  rec = null;
  recResult = null;
  recognizedText = null;
  internalRecResult = null;
  understood: boolean;
  difficulty: any;
  expectedInput: any;
  lang: any;
  speakingTry = 0;

  constructor(
    private http: HttpClient,
    private helper: HelpersService,
    public quizService: QuizService,
    private platform: PlatformService,
    private userDetailsService: UserDetailsService
  ) {
    var { webkitSpeechRecognition }: IWindow = <IWindow>(<unknown>window);
    var recognition = new webkitSpeechRecognition();
    if (recognition) {
      this.browserRecognitionSupported = true;
    } else {
      this.browserRecognitionSupported = false;
    }
    this.url = this.platform.url;
    // this.url = 'https://lingking.com.pl:3001';
  }
  // Helper function to convert Float32Array to WAV Blob
  float32ArrayToWavBlob = (
    float32Array: Float32Array,
    sampleRate: number
  ): Blob => {
    const buffer = new ArrayBuffer(44 + float32Array.length * 2);
    const view = new DataView(buffer);

    // Write WAV header
    view.setUint32(0, 0x52494646, true); // "RIFF"
    view.setUint32(4, 36 + float32Array.length * 2, true);
    view.setUint32(8, 0x57415645, true); // "WAVE"
    view.setUint32(12, 0x666d7420, true); // "fmt "
    view.setUint32(16, 16, true); // Subchunk1Size (16 for PCM)
    view.setUint16(20, 1, true); // AudioFormat (1 for PCM)
    view.setUint16(22, 1, true); // NumChannels
    view.setUint32(24, sampleRate, true); // SampleRate
    view.setUint32(28, sampleRate * 2, true); // ByteRate
    view.setUint16(32, 2, true); // BlockAlign
    view.setUint16(34, 16, true); // BitsPerSample
    view.setUint32(36, 0x64617461, true); // "data"
    view.setUint32(40, float32Array.length * 2, true);

    // Write audio data
    for (let i = 0, offset = 44; i < float32Array.length; i++, offset += 2) {
      const sample = Math.max(-1, Math.min(1, float32Array[i]));
      view.setInt16(
        offset,
        sample < 0 ? sample * 0x8000 : sample * 0x7fff,
        true
      );
    }

    return new Blob([view], { type: 'audio/wav' });
  };

  private async processAudio(audioFile: File): Promise<Blob> {
    const audioContext = new AudioContext();

    // Helper function to decode the audio data
    const decodeAudioData = (audioFile: File) =>
      new Promise<AudioBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
          audioContext.decodeAudioData(
            reader.result as ArrayBuffer,
            resolve,
            reject
          );
        reader.readAsArrayBuffer(audioFile);
      });

    // Helper function to convert AudioBuffer to Blob
    // Helper function to convert AudioBuffer to Blob
    const audioBufferToBlob = (audioBuffer: AudioBuffer): Blob => {
      const wavBuffer = toWav(audioBuffer);
      return new Blob([wavBuffer], { type: 'audio/wav' });
    };

    // Load the audio data into an AudioBuffer
    const audioBuffer = await decodeAudioData(audioFile);

    // Create an OfflineAudioContext to process the audio data
    const offlineContext = new OfflineAudioContext({
      numberOfChannels: audioBuffer.numberOfChannels,
      length: audioBuffer.length,
      sampleRate: audioBuffer.sampleRate,
    });

    // Create a gain node for volume boost
    const gainNode = offlineContext.createGain();
    gainNode.gain.value = 1.2; // Adjust this value for volume boost

    // Create a biquad filter for noise reduction
    const filterNode = offlineContext.createBiquadFilter();
    filterNode.type = 'lowpass';
    filterNode.frequency.value = 1200;
    // Adjust this value for noise reduction

    // Create a dynamics compressor node for automatic gain control
    const compressorNode = offlineContext.createDynamicsCompressor();
    compressorNode.threshold.value = -45;
    compressorNode.knee.value = 30;
    compressorNode.ratio.value = 8;
    compressorNode.attack.value = 0.003;
    compressorNode.release.value = 0.1;

    // Process the audio data
    const sourceNode = offlineContext.createBufferSource();
    sourceNode.buffer = audioBuffer;
    sourceNode
      .connect(gainNode)
      .connect(filterNode)
      .connect(compressorNode) // Add the compressor node to the chain
      .connect(offlineContext.destination);

    sourceNode.start(0);
    const updatedAudioBuffer = await offlineContext.startRendering();
    const updatedAudioBlob = audioBufferToBlob(updatedAudioBuffer);
    return updatedAudioBlob;
  }

  // transcribe(audioFile: File) {
  //   this.http
  //     .post(this.url + '/api/chat/transcribe', {
  //       file: audioFile,
  //     })
  //     .subscribe((response: any) => {
  //       console.log(
  //         '🚀 ~ file: chat.service.ts:44 ~ ChatService ~ .subscribe ~ response',
  //         response
  //       );
  //     });
  // }
  transcribe(audioFile: File): Observable<any> {
    const headers = new HttpHeaders();
    const options = { headers };

    return from(this.processAudio(audioFile)).pipe(
      switchMap((updatedAudioBlob) => {
        const formData = new FormData();
        formData.append('file', updatedAudioBlob, 'audio.wav');
        return this.http.post<any>(
          this.url + '/api/chat/transcribe',
          formData,
          options
        );
      })
    );
  }

  startBrowserRecognition(expectedInput, possibilities, lang?, difficulty?) {
    this.difficulty = difficulty;
    this.expectedInput = expectedInput;
    this.lang = lang;
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:149 ~ SpeechRecognitionService ~ startBrowserRecognition ~ difficulty:',
      difficulty
    );
    let language = null;
    switch (lang) {
      case 'english':
        language = 'en-US';
        break;
      case 'german':
        language = 'de-DE';
        break;
      case 'french':
        language = 'fr-FR';
        break;
      case 'spanish':
        language = 'es-ES';
        break;

      default:
        break;
    }
    var { webkitSpeechRecognition }: IWindow = <IWindow>(<unknown>window);
    var recognition = new webkitSpeechRecognition();
    this.rec = recognition;
    this.rec.continuous = true;
    if (!lang) {
      recognition.lang = 'en-US';
    } else {
      recognition.lang = language;
    }
    recognition.interimResults = false;
    recognition.maxAlternatives = 10;
    recognition.start();
    console.log('recognition started!');
    recognition.onstart = (e) => {
      this.recognitionData.next({ listening: true });
    };

    recognition.onend = (e) => {
      this.internalRecResult = e;
    };
    recognition.onerror = (e) => {
      console.log('e: ', e);
      this.recognitionPending = false;
      this.recognitionData.next({ listening: false });
      this.recognitionData.next({ understood: false });

      recognition = null;
    };
    recognition.onnomatch = (e) => {
      console.log('e: ', e);
      this.recognitionPending = false;
      this.recognitionData.next({ listening: false });
      this.recognitionData.next({ understood: false });
      recognition = null;
    };

    recognition.onresult = (e) => {
      let result = e.results;
      console.log('result: ', result);
      if (expectedInput) {
        this.recResult = result;
      }
    };
  }
  stopBrowserRecognition() {
    this.rec.stop();
    this.checkSimilarity(this.expectedInput, this.recResult, this.difficulty);
    this.recognitionPending = false;
  }
  checkSimilarity(expectedInput, result, difficulty?, fromExt?, noSummary?) {
    this.ratioToCorrectAnswer = 0;
    let similaritiesArray = [];
    console.log('result: ', result);
    let resultsArray = [];
    if (this.browserRecognitionSupported && !fromExt) {
      resultsArray = Array.from(result?.[0] ?? []);
    } else {
      resultsArray = [{ transcript: result }];
      // resultsArray = Array.from(result?.results?.[0]?.alternatives ?? []);
    }
    console.log('resultsArray: ', resultsArray);
    const correctedSentence = resultsArray[0].transcript;
    resultsArray.forEach((element) => {
      const similarity = this.compareStrings(
        this.helper
          .removeSpecialCharsAndSpaces(
            expectedInput.replace('A: ', '').replace('B: ', '')
          )
          .trim()
          .toLowerCase(),
        this.helper
          .removeSpecialCharsAndSpaces(
            correctedSentence.replace('A:', '').replace('B:', '')
          )
          .trim()
          .toLowerCase()
      );
      similaritiesArray.push({
        word: correctedSentence.toLowerCase().trim().replace('.', ''),
        similarity: similarity,
      });
    });

    similaritiesArray = this.helper.sortArrayByProperty(
      similaritiesArray,
      'similarity',
      -1
    );
    console.log('similaritysArray: ', similaritiesArray);
    let selectedOption;
    selectedOption = similaritiesArray.find(
      (element) => element.word == expectedInput
    );
    if (!selectedOption) {
      selectedOption = similaritiesArray[0];
    }
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:297 ~ SpeechToTextService ~ checkSimilarity ~ selectedOption:',
      selectedOption
    );
    this.recognizedText = selectedOption.word;
    const similarityToCorrectAnswer = this.compareStrings(
      this.helper
        .removeSpecialCharsAndSpaces(
          expectedInput.replace('A: ', '').replace('B: ', '')
        )
        .trim()
        .toLowerCase(),
      this.helper
        .removeSpecialCharsAndSpaces(
          selectedOption?.word.replace('A:', '').replace('B:', '')
        )
        .trim()
        .toLowerCase()
    );

    let difficultyRate = 0;
    if (difficulty == 'easy') {
      difficultyRate =
        this.browserRecognitionSupported && !fromExt ? 0.7 : 0.75;
    } else if (difficulty == 'medium') {
      difficultyRate =
        this.browserRecognitionSupported && !fromExt ? 0.8 : 0.85;
    } else if (difficulty == 'hard') {
      difficultyRate =
        this.browserRecognitionSupported && !fromExt ? 0.9 : 0.95;
    }
    // if (
    //   this.userDetailsService.getSimpleUserDetails().id !==
    //   '61ea960d46121817c98b4dd3'
    // ) {
    difficultyRate = difficultyRate - this.speakingTry * 0.05;
    console.log(
      '🚀 ~ file: speech-recognition.service.ts:337 ~ SpeechToTextService ~ checkSimilarity ~ difficultyRate:',
      difficultyRate
    );
    // }
    this.ratioToCorrectAnswer = similarityToCorrectAnswer;
    if (similarityToCorrectAnswer > difficultyRate) {
      if (!noSummary) {
        this.quizService.checkAnswer(
          'correct',
          this.ratioToCorrectAnswer,
          null,
          this.recognizedText
        );
      }

      this.outputResult(expectedInput);
    } else {
      if (!noSummary) {
        this.quizService.presentActionSheet(
          'wrong',
          this.ratioToCorrectAnswer > 0 ? this.ratioToCorrectAnswer : 0.01,
          null,
          this.recognizedText
        );
      }
      this.outputResult(null);
    }
    this.ratioToCorrectAnswer = 0;
  }
  outputResult(result) {
    this.understood = true;
    this.recognitionData.next(result);

    this.recognitionPending = false;
  }
  stopRecognition() {
    this.recognitionPending = false;
    this.recognitionData.next({ listening: false });
    this.recognitionData.next({ understood: false });
    if (this.rec) {
      this.rec.abort();
    }
    console.log('this.rec: ', this.rec);
  }
  compareStrings(transcribedAudio: string, expectedAnswer: string): number {
    // const similarity = this.jaroWinkler(correctedSentence, expectedAnswer, 0.1);
    const similarity = this.compareTwoStrings(
      transcribedAudio,
      expectedAnswer,
      0.1
    );
    return similarity;
  }

  // Jaro-Winkler similarity function

  getBigrams(string) {
    const bigrams = new Set();
    for (let i = 0; i < string.length - 1; i++) {
      bigrams.add(string.substr(i, 2));
    }
    return bigrams;
  }

  compareTwoStrings(first, second, weight) {
    first = first.replace(/\s+/g, '');
    second = second.replace(/\s+/g, '');

    const numWordsWeight = weight || 0.1; // default weight is 0.1

    if (first === second) return 1; // identical or empty
    if (first.length < 2 || second.length < 2) return 0; // if either is a 0-letter or 1-letter string

    let firstBigrams = new Map();
    for (let i = 0; i < first.length - 1; i++) {
      const bigram = first.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) + 1 : 1;

      firstBigrams.set(bigram, count);
    }

    let intersectionSize = 0;
    for (let i = 0; i < second.length - 1; i++) {
      const bigram = second.substring(i, i + 2);
      const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram) : 0;

      if (count > 0) {
        firstBigrams.set(bigram, count - 1);
        intersectionSize++;
      }
    }

    // compute the number of words in the strings and apply the weight
    const firstNumWords = first.split(/\s+/).length;
    const secondNumWords = second.split(/\s+/).length;
    const numWordsScore =
      (Math.abs(firstNumWords - secondNumWords) /
        Math.max(firstNumWords, secondNumWords)) *
      numWordsWeight;

    return (
      (2.0 * intersectionSize) / (first.length + second.length - 2) -
      numWordsScore
    );
  }

  /**
   * Combines two sentences by randomly selecting tokens from each sentence.
   * If one sentence is shorter than the other, the remaining tokens are appended to the output sentence.
   * If the recognition sentence has fewer than a certain percentage of missing words, missing words from the expected sentence are added.
   * @param {string} expected - The expected sentence.
   * @param {string} recognition - The recognition sentence.
   * @param {number} alpha - The probability of selecting a token from the expected sentence.
   *                          Must be between 0 and 1. Default is 0.5.
   * @param {number} missingWordsPercentage - The minimum percentage of missing words in the recognition sentence
   *                                          that will trigger the addition of missing words from the expected sentence.
   *                                          Must be between 0 and 100. Default is 10.
   * @param {number} remainingTokensPercentage - The percentage of remaining tokens to include from the longer sentence.
   *                                              Must be between 0 and 100. Default is 100.
   * @returns {string} The combined sentence.
   */
  improveRecognition(
    expected,
    recognition,
    alpha = 0.5,
    missingWordsPercentage = 80,
    remainingTokensPercentage = 50
  ) {
    const expectedTokens = expected.toLowerCase().split(/\s+/);
    const recognitionTokens = recognition.toLowerCase().split(/\s+/);
    const numMissingWords = recognitionTokens.filter(
      (token) => !expectedTokens.includes(token)
    ).length;
    const missingWordsRatio = numMissingWords / expectedTokens.length;

    // If the recognition sentence is empty or has too few missing words, return the recognition sentence
    if (
      recognitionTokens.length === 0 ||
      missingWordsRatio < missingWordsPercentage / 100
    ) {
      return recognition;
    }

    const minLength = Math.min(expectedTokens.length, recognitionTokens.length);
    const combinedTokens = [];

    // Combine tokens from the expected and recognition sentences
    for (let i = 0; i < minLength; i++) {
      if (Math.random() <= alpha) {
        combinedTokens.push(expectedTokens[i]);
      } else {
        combinedTokens.push(recognitionTokens[i]);
      }
    }

    // Append remaining tokens from the longer sentence
    if (expectedTokens.length > minLength) {
      const numRemainingTokens = Math.round(
        ((expectedTokens.length - minLength) * remainingTokensPercentage) / 100
      );
      combinedTokens.push(
        ...expectedTokens.slice(minLength, minLength + numRemainingTokens)
      );
    } else if (recognitionTokens.length > minLength) {
      const numRemainingTokens = Math.round(
        ((recognitionTokens.length - minLength) * remainingTokensPercentage) /
          100
      );
      const missingTokens = recognitionTokens.filter(
        (token) => !expectedTokens.includes(token)
      );
      const numMissingTokensToUse = Math.round(
        missingTokens.length *
          (missingWordsRatio - missingWordsPercentage / 100)
      );
      const selectedMissingTokens = [];
      for (let i = 0; i < numMissingTokensToUse; i++) {
        const randomIndex = Math.floor(Math.random() * missingTokens.length);
        selectedMissingTokens.push(missingTokens[randomIndex]);
        missingTokens.splice(randomIndex, 1);
      }
      combinedTokens.push(...selectedMissingTokens);
      combinedTokens.push(
        ...recognitionTokens.slice(
          minLength,
          minLength + numRemainingTokens - numMissingTokensToUse
        )
      );
    }

    return combinedTokens.join(' ');
  }
  recognitionResultListener() {
    return this.recognitionData.asObservable();
  }
}
