import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HelpersService } from '../../services/helpers.service';
import { PlatformService } from '../../services/platform.service';

@Injectable({
  providedIn: 'root'
})
export class ChatbotsService {
  url
  constructor(
    private http: HttpClient,
    private helpersService: HelpersService,
    private platformService: PlatformService
  ) {
    this.url = this.platformService.url;
  }
  chatbots = [
    {
      name: 'Matt',
      description: 'Matt jest chatbotem, który będzie rozmawiał tobą po angielsku i poprawiał błędy językowe',
      avatar: '/assets/people-speaking-plain/3.png',
      aiProvider: 'claude',
      model: 'claude-3-5-sonnet-20240620',
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      systemMsg: 'Your name is Matt. You teach an english as a second language and are patient, friendly and professional teacher who helps students with conversations. Correct mistakes and give hints. Steer the conversation for the best learning result. The conversation should be in english but the rest such as the explanations and hints and comments and responses in polish. add a <hr> tag after a response to separate it from the explanation Use max 100 words in responses',
    },
    {
      name: 'Joe',
      description: 'Joe jest chatbotem, który będzie odpowiadał na pytania językowe i wyjaśniał Twoje wątpliwości',
      avatar: '/assets/people-speaking-plain/4.png',
      aiProvider: 'claude',
      model: 'claude-3-5-sonnet-20240620',
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      systemMsg: 'Masz na imię Joe. Jesteś przyjaznym, cierpliwym i profesjonalnym nauczycielem języka angielskiego, który pomaga studentom z pytaniami i wątpliwościami. Odpowiadaj na pytania i wyjaśniaj wątpliwości. Wyjaśniaj wątpliwości stosując łatwe do zrozumienia przykłady i skojarzenia. Rozmowa powinna być w języku polskim, chyba e uczeń poprosi o angielski.Użyj maksymalnie 150 słów w odpowiedziach',
    },
    {
      name: 'Hector',
      description: 'Hector jest chatbotem, który będzie rozmawiał tobą po hiszpańsku i tłumaczył detale i poprawiał błędy',
      avatar: '/assets/people-speaking-plain/5.png',
      aiProvider: 'claude',
      model: 'claude-3-5-sonnet-20240620',
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: 'Your name is Hector. You teach an spanish as a second language teacher who helps students with conversations. Correct mistakes and give hints. Steer the conversation for the best learning result. The conversation should be in spanish but the rest such as the explanations and hints and comments and responses in polish. add a <hr> tag after a response to separate it from the explanation Use max 150 tokens in responses',
    },
    {
      name: 'Lalo',
      description: 'Lalo jest chatbotem, który będzie poprawiał błędy i formuował poprawne zdania',
      avatar: '/assets/people-speaking-plain/5.png',
      aiProvider: 'claude',
      model: 'claude-3-5-sonnet-20240620',
      maxTokens: 300,
      maxMessages: 5,
      hasVision: true,
      avalibleFor: ['Owner', 'Office', 'Teacher'],
      systemMsg: 'Your name is Lalo. You teach a mexican spanish as a second language teacher who helps students with conversations. Correct mistakes and give hints.  The conversation should be in spanish but the rest such as the explanations and hints and comments and responses in english. Correct students sentence and explain why it is wrong. First respond with a correct sentence and then  write the english translation of the users sentence and add a <hr> tag after the sentence. If the user inputs a single word then translate it to spanish. If the user wants the translation then write the translation, nothing else The explanation should be very short and clear.',
    },
  ]

  sendPromptToChosenAi(data: {
    messages,
    aiProvider: string,
    model: string,
    maxTokens?: number,
    maxMessages?: number,
    systemMsg?: string,
    files?
  }): Observable<any> {
    const msgs = this.helpersService.clone(data.messages)
    const messagesToSend = msgs.map((message) => {
      return {
        role: message.role,
        content: message.content,
      };
    }
    );
    const formData = new FormData();
    formData.append('messages', JSON.stringify(messagesToSend));
    formData.append('aiProvider', data.aiProvider);
    formData.append('model', data.model);
    if (data.maxTokens) {
      formData.append('maxTokens', data.maxTokens.toString());
    }
    if (data.maxMessages) {
      formData.append('maxMessages', data.maxMessages.toString());
    }
    if (data.systemMsg) {
      formData.append('systemMsg', data.systemMsg);
    }

    console.log("🚀 ~ ChatService ~ data:", data)
    if (data.files && data.files.length > 0) {
      Array.from(data.files).forEach((file: any, index) => {
        formData.append(`files`, file, file.name);
      });
    }

    return this.http.post(this.url + '/api/chat/send-prompt-to-chosen-ai', formData);
  }
}
