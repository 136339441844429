import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNoLetterInput]',
})
export class NoLetterInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const letterRegex = /[a-zA-Z]/g;
    const inputValue = this.el.nativeElement.value;

    this.el.nativeElement.value = inputValue.replace(letterRegex, '');
  }
}
