import { Injectable } from '@angular/core';
import { PlatformService } from './platform.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { HelpersService } from './helpers.service';
import { AuthenticationService } from 'src/app/authentication/authentication.service';
import { UserDetailsService } from 'src/app/home/progress/user-details.service';
import { ModalController, ToastController } from '@ionic/angular';
import { ChooseCustomSetComponent } from '../components/choose-custom-set/choose-custom-set.component';

@Injectable({
  providedIn: 'root',
})
export class SetsService {

  url;
  setContent = new Subject();

  constructor(
    private modalController: ModalController,
    private platform: PlatformService,
    private http: HttpClient,
    private helpers: HelpersService,
    private authService: AuthenticationService,
    private toastController: ToastController
  ) {
    this.url = platform.url;
  }

  getSetContent(id, usersId) {
    this.http
      .post<{ sets: any }>(this.url + '/api/sets/getset', { id })
      .pipe()
      .subscribe((set) => {
        console.log("🚀 ~ file: sets.service.ts:35 ~ SetsService ~ .subscribe ~ set:", set)
        if (!set?.sets[0]) return;
        const _set = set.sets[0];

        let translatedSet = this.helpers.translateObjectFromBackend(_set);
        translatedSet.itemsLoaded = true;
        translatedSet.parentSet = id;
        // translatedSet.id = usersId;
        this.setContent.next(translatedSet);
        console.log(
          '🚀 ~ file: sets.service.ts:32 ~ SetsService ~ .subscribe ~ translatedSet:',
          translatedSet
        );

        // this.checkAudio('wordAudio')
      });
  }
  async addUsersSet(name, owner) {
    const newSet = {
      added: Date.now(),
      items: [],
      owner: owner,
      lastModified: Date.now(),
      name: name,
      type: 'exercise',

    };
    this.http.put(this.url + '/api/sets/add-custom-users-set', { set: newSet }, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).subscribe(async (response) => {

    });
  }

  addSetToUser(userId, set) {
    console.log(
      '🚀 ~ file: sets.service.ts:44 ~ SetsService ~ addSetToUser ~ set:',
      set
    );
    console.log(
      '🚀 ~ file: sets.service.ts:44 ~ SetsService ~ addSetToUser ~ userId:',
      userId
    );
    console.log('adding set to user...');
    let setToSend = this.helpers.clone(set);
    setToSend.items = setToSend.items.map((item) => {
      return { wordId: item.wordId, meaningId: item.meaningId, item: {} };
    });
    console.log(
      '🚀 ~ file: sets.service.ts:60 ~ SetsService ~ setToSend.items=setToSend.items.map ~  setToSend:',
      setToSend
    );
    // setToSend.id = this.helpers.makeid(8);
    // set.id = setToSend.id;
    this.http
      .put(
        this.url + '/api/users/addsettouser/' + userId,
        { set: setToSend },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe((response) => { });
  }
  changeSetsName(setId) {
    let name = prompt('Wpisz nową nazwę zestawu');
    if (!name) return;
    this.http.put(this.url + '/api/sets/change-sets-name/' + setId, { name: name }, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).subscribe((response) => {
      console.log("🚀 ~ file: sets.service.ts:95 ~ SetsService ~ this.http.put ~ response:", response)
    }
    );
  }
  removeSet(setId) {
    const confirm = window.confirm('Czy na pewno chcesz usunąć zestaw razem ze wszystkimi słowami?');
    if (!confirm) return;
    this.http.delete(this.url + '/api/sets/removeset/' + setId, { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }).subscribe((response) => {
      console.log("🚀 ~ file: sets.service.ts:95 ~ SetsService ~ this.http.put ~ response:", response)
    }
    );
  }
  removeItemFromSet(item, setId) {
    const itemToRemove = { wordId: item.wordId, meaningId: item.id, item: {} }
    this.http
      .put(
        this.url + '/api/sets/remove-item-from-set/' + setId,
        { item: itemToRemove },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe((response) => {
        console.log(response);
        this.getSetContent(setId, null);
      });
  }

  async addItemsToSet(item: { wordId: string, meaningId: string, item: any }) {
    const usersSets = this.helpers.usersSets;
    const modal = await this.modalController.create({
      component: ChooseCustomSetComponent,
      componentProps: {
        usersSets: usersSets
      }
    });

    await modal.present();

    // Using onDidDismiss
    const { data } = await modal.onDidDismiss();

    console.log('Received data:', data);
    // Handle the received data

    const setId = data
    if (!setId) return;
    this.http
      .put(
        this.url + '/api/sets/add-item-to-set/' + setId, { item: item },
        { headers: { Authorization: `Bearer ${this.authService.getToken()}` } }
      )
      .subscribe(async (response) => {
        console.log(response);
        const toast = await this.toastController.create({
          message: 'Słowo zostało dodane',
          position: 'bottom',
          color: 'success',
          duration: 2000

        });
        toast.present();
      });
  }
  searchForSetsbyId(setId) {
    this.http
      .post<{ sets: any }>(this.url + '/api/sets/findsetbyid', {
        setToFind: setId,
      })
      .pipe()
      .subscribe((set) => {
        const _set = set.sets[0];
        const translatedSet = this.helpers.translateObjectFromBackend(_set);
        this.setContent.next(translatedSet);
      });
  }
}
