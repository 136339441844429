import { Injectable } from '@angular/core';
import { Client, Room } from 'colyseus.js';

@Injectable({
  providedIn: 'root'
})
export class LiveGameCommunicatorService {
  private client: Client;
  private room: Room;

  constructor() {
    this.client = new Client('wss://backend-dev.linget.it/'); // Use wss for secure WebSocket
    this.room?.onStateChange((state) => {
      console.log(state);
      for (let player of state.players) {
        console.log(player);
      }
    });

  }
  async createRoom(roomName: string, options: any) {
    console.log('creating room, client:', this.client)
    this.room = await this.client.create(roomName, options);
    console.log("🚀 ~ LiveGameCommunicatorService ~ createRoom ~ this.room:", this.room)
    this.room.onMessage("message", (message) => {
      console.log(message);
    });

    this.room.onStateChange((state) => {
      console.log(state);
    });

    this.room.onError((code, message) => {
      console.error(`Error ${code}: ${message}`);
    });

    this.room.onLeave((code) => {
      console.log(`Left the room with code ${code}`);
    });
  }



  async joinRoom(roomName: string, options: any) {
    this.room = await this.client.join(roomName, options);
    console.log("🚀 ~ LiveGameCommunicatorService ~ joinRoom ~ this.room:", this.room)
    this.room.onMessage("message", (message) => {
      console.log(message);

    });

    this.room.onStateChange((state) => {
      console.log(state);
    });

    this.room.onError((code, message) => {
      console.error(`Error ${code}: ${message}`);
    });

    this.room.onLeave((code) => {
      console.log(`Left the room with code ${code}`);
    });

  }

  sendMessage(message: any) {
    if (this.room) {
      this.room.send("message", message);
    }
  }
}