import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { PlatformService } from '../shared/services/platform.service';

export interface UserDetails {
  _id: string;
  email: string;
  name: string;
  role: string;
  status: string;
  exp: number;
  iat: number;
}

interface TokenResponse {
  token: string;
  duplicateUser?: boolean;
}

export interface TokenPayload {
  email: string;
  password: string;
  name?: string;
  role?: string;
  status?: string;
  userData?;
}

@Injectable()
export class AuthenticationService {
  url;
  userBackendMessages = new Subject();
  private token: string;
  private routerSubscription: Subscription;
  usersStudentsUpdated = new Subject();
  constructor(
    private http: HttpClient,
    private router: Router,
    private platform: PlatformService
  ) {
    const loc = window.location.hostname;
    this.url = this.platform.url + '/api/users/';
    this.subscribeToNavigationEnd();

    //   if (loc !== 'test.lingking.com.pl' && loc !== 'lingking.com.pl') {
    //     // this.url = 'http://' + window.location.hostname + ':3000/api/users/';
    //     // this.url = 'https://lingking.com.pl:3001/api/users/';
    //     this.url = 'https://backend.linget.it/api/users/';
    //   } else {
    //     // this.url = 'https://lingking.com.pl:3001/api/users/';
    //     this.url = 'https://backend.linget.it/api/users/';

    //     // this.fileUrl = this.url +'/api/sentences/'
    //   }
  }

  public getToken(): string {
    if (!this.token) {
      this.token = localStorage.getItem('mean-token');
    }
    return this.token;
  }

  public getUserDetails(): UserDetails {
    const token = this.getToken();
    let payload;
    if (token) {
      payload = token.split('.')[1];
      payload = window.atob(payload);
      return JSON.parse(payload);
    } else {
      return null;
    }
  }

  public isLoggedIn(): boolean {
    const user = this.getUserDetails();
    if (user) {
      return user.exp > Date.now() / 1000;
    } else {
      return false;
    }
  }

  public register(user: TokenPayload): Observable<any> {
    // this.request('post', 'register', user).subscribe(result=>{
    //
    //   if(result.duplicateUser === true){
    //     user.email = user.email +'1'
    //
    //     this.register(user)
    //   }
    // })
    return this.request('post', 'register', user);
  }

  public login(user: TokenPayload): Observable<any> {
    return this.request('post', 'login', user);
  }

  public profile(): Observable<any> {
    return this.request('get', 'profile');
  }

  changePassword(oldPassword, newPassword, id) {
    this.http
      .post<{ message: any }>(this.url + 'changepassword', {
        oldPassword,
        newPassword,
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message.newUserId);
      });
  }

  setPassword(newPassword, id) {
    this.http
      .post<{ message: any }>(this.url + 'setpassword', {
        newPassword,
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  getUSersStudents(studentsIds) {
    this.http
      .post<{ message: any }>(this.url + 'get-users-students', {
        studentsIds,
      })
      .subscribe((message) => {
        this.usersStudentsUpdated.next(message);
      });
  }

  changeAppsEmail(newEmail, id) {
    console.log(
      '🚀 ~ file: authentication.service.ts ~ line 169 ~ AuthenticationService ~ changeAppsEmail ~ id',
      id
    );
    this.http
      .post<{ message: any }>(this.url + 'changeEmail', {
        newEmail,
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  forgotPassword(email) {
    this.http
      .post<{ message: any }>(this.url + 'forgot', { email })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  resetPassword(token, newPassword) {
    this.http
      .post<{ message: any }>(this.url + 'reset', {
        token,
        newPassword,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  resetFreshPassword(id) {
    this.http
      .post<{ message: any }>(this.url + 'resetfreshpassword', { id })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }

  resetFreshWithTempPassword(id) {
    this.http
      .post<{ message: any }>(this.url + 'reset-fresh-with-temp-password', {
        id,
      })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  sendLoginCode(email): Observable<boolean> {
    return this.http
      .post<{ message: any }>(this.url + 'request-login', { email })
      .pipe(
        map((message: any) => {
          if (message.message === 'Code sent') {
            alert('Wysłano kod dostępu na adres e-mail');
            return true;
          }
          return false;
        }),
        catchError((error: any) => {
          console.error('An error occurred:', error);
          if (error.statusText === 'Not Found') {
            alert('Błędny adres e-mail!');
          }
          return of(false);
        })
      );
  }
  getCodeForStudent(student) {
    console.log(
      '🚀 ~ file: authentication.service.ts:220 ~ AuthenticationService ~ getCodeForStudent ~ student:',
      student
    );
    this.http
      .post<{ message: any }>(this.url + 'generate-code', {
        id: student._id,
      })
      .subscribe((message: any) => {
        if (message.code) {
          alert(
            'jednorazowy kod dostępu dla ucznia ' +
              student.name +
              ' to ' +
              message.code
          );
        }
      });
  }

  loginWithCode(code): boolean {
    let result = false;
    this.http
      .post<{ message: any }>(this.url + 'login-with-code', { code })
      .subscribe(
        (data: any) => {
          // Success block
          this.saveToken(data.token);
          this.router.navigateByUrl('/home/profile');
          result = true;
        },
        (err) => {
          // Error block
          console.error('An error occurred:', err);
          alert(
            'Błędny kod lub nie znaleziono uzytkownika. Sprawdź połączenie z internetem oraz kod. Pamiętaj, ze kod jest jednorazowy. Jeśli juz wcześniej uzyłeś tego kodu, musisz wygenerować inny.'
          );
          result = false;
        }
      );
    return result;
  }
  loginWithLink(token): boolean {
    let result = false;
    this.http
      .post<{ message: any }>(this.url + 'login-with-link', { token: token })
      .subscribe(
        (data: any) => {
          console.log(
            '🚀 ~ file: authentication.service.ts:237 ~ AuthenticationService ~ .subscribe ~ data:',
            data
          );
          if (data.message === 'Token has expired') {
            alert('Link wygasł');
            result = false;
            return;
          }
          if (data.message === 'Invalid token or user not found') {
            alert('Link jest niepoprawny');
            result = false;
            return;
          }
          // Success block
          else {
            this.token = '';
            window.localStorage.removeItem('mean-token');
            this.saveToken(data.token);
            result = true;
            this.router.navigateByUrl(
              '/home/customer-panel?showContracts=true'
            );
          }
        },
        (err) => {
          alert(
            'Błędny lub nieważny link. Link wany jest 7 dni. Jeśli ten czas upłynął, skorzystaj z logowania kodem dostępu.'
          );
        }
      );
    return result;
  }

  sendEmail(id) {
    this.http
      .post<{ message: any }>(this.url + 'sendemail', { id })
      .pipe(map((message) => message.message))
      .subscribe((message) => {
        this.userBackendMessages.next(message);
      });
  }
  private subscribeToNavigationEnd(): void {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log('Navigation has ended!', event);
        if (event.id !== 1 && event.url == '/login') {
          window.location.reload();
        }
      });
  }
  public logout(): void {
    this.token = '';
    window.localStorage.removeItem('mean-token');
    sessionStorage.removeItem('userDetails');
    this.router.navigateByUrl('/login');

    // window.location.reload();
  }

  userBackendMessagesListener() {
    return this.userBackendMessages.asObservable();
  }

  private saveToken(token: string): void {
    localStorage.setItem('mean-token', token);
    this.token = token;
  }

  private request(
    method: 'post' | 'get',
    type: 'login' | 'register' | 'profile',
    user?: TokenPayload
  ): Observable<any> {
    let base;

    if (method === 'post') {
      base = this.http.post(this.url + type, user);
    } else {
      base = this.http.get(this.url + type, {
        headers: { Authorization: `Bearer ${this.getToken()}` },
      });
    }

    const request =
      //  this.http.post(`http://lingking.com.pl:3000api/login`, user)
      base.pipe(
        map((data: TokenResponse) => {
          if (data.token) {
            this.saveToken(data.token);
          }

          return data;
        })
      );

    return request;
  }
  usersStudentsListener() {
    return this.usersStudentsUpdated.asObservable();
  }
  ngOnDestroy(): void {
    // Don't forget to unsubscribe
    this.routerSubscription.unsubscribe();
  }
}
